import { DateTime } from "luxon";
import { useState } from "react";
import FormGroup from "./FormGroup";

const WeekSelector = (props) => {
    const { defaultWeeks, refreshPreview } = props;

    const [weeks, setWeeks] = useState(defaultWeeks);

    const updateYear = (weekIndex, value) => {
        const data = [...weeks];
        data[weekIndex].year = value;

        const d = DateTime.fromFormat(`${data[weekIndex].year} ${data[weekIndex].week}`, 'kkkk W');
        data[weekIndex].date = d;
        setWeeks(data);
        refreshPreview(data);
    }
    const updateWeek = (weekIndex, value) => {
        const data = [...weeks];
        data[weekIndex].week = value;

        const d = DateTime.fromFormat(`${data[weekIndex].year} ${data[weekIndex].week}`, 'kkkk W');
        data[weekIndex].date = d;
        setWeeks(data);
        refreshPreview(data);
    }

    const removeWeek = (weekIndex) => {
        const data = [...weeks];
        data.splice(weekIndex, 1);
        setWeeks(data);
        refreshPreview(data);
    }

    const addWeek = () => {
        const d = DateTime.fromFormat(`${weeks[weeks.length - 1].year} ${weeks[weeks.length - 1].week}`, 'kkkk W');
        const dd = d.plus({ week: 1 })
        console.log(d);
        const data = [...weeks, { year: dd.toFormat('kkkk'), week: dd.toFormat('W'), date: dd}];
        setWeeks(data);
        refreshPreview(data);
    }

    return <FormGroup labelKey="weeksSelector" label="Selected Weeks">
        <div className="overflow-y-auto max-h-56 shadow-inner p-2">
            {weeks.map((weekConfig, yi) => (
                <div key={yi} className="flex flex-row items-middle grow">

                    <input type="text" style={{ width: '70px' }} className="border py-2 px-3 text-grey-darkest" value={weekConfig.year} onChange={event => updateYear(yi, event.target.value)} />
                    <input type="text" style={{ width: '70px' }} className="border py-2 px-3 text-grey-darkest" value={weekConfig.week} onChange={event => updateWeek(yi, event.target.value)} />

                    <div className="w-1/3 p-2 flex justify-items-stretch">
                        {weeks.length > 1 && <div className="justify-self-end">
                            <button onClick={() => removeWeek(yi)} type="button" className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </div>}
                        {weeks.length === 1 && <div style={{ width: '30px' }}></div>}
                    </div>
                </div>
            ))}
        </div>
        <div className="w-1/3 flex justify-items-stretch my-4 mx-1">
            <div className="justify-self-end">
                <button onClick={addWeek} type="button" className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-green-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
        </div>
    </FormGroup>
}

export default WeekSelector;