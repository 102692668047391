import { usePDF } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import useWindowDimensions from './useWindowDimensions';

const Preview = (props) => {
	const { pageFactory, configuration: { years, weeks, prependZero, showWeekends, startWeekOnSundays, showWeekColumn, themeNameOrTheme, orientation, paperSize, layout, customMarkings, pageTitleTemplate, startOfDay, endOfDay, alignmentContentCellHorizontal, alignmentContentCellVertical, alignmentHeaderCell, alignmentWeekCell } } = props;

	// not happy with this but we need an absolute size for the pdf preview and we know the magic number to subtract so it fits and works :-(
	const { height, width } = useWindowDimensions();
	const [instance, updateInstance] = usePDF({ document: pageFactory() });
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [rendered, setRendered] = useState(false);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		setRendered(true);
	}

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			setRendered(false);
			updateInstance();
		}, 500);
		return () => clearTimeout(timeOutId);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [years, weeks, prependZero, showWeekends, startWeekOnSundays, showWeekColumn, themeNameOrTheme, orientation, paperSize, layout, customMarkings, pageTitleTemplate, startOfDay, endOfDay, alignmentContentCellHorizontal, alignmentContentCellVertical, alignmentHeaderCell, alignmentWeekCell]
	);

	const prefPage = () => {
		setPageNumber(pageNumber - 1);
	}

	const nextPage = () => {
		setPageNumber(pageNumber + 1);
	}

	return <div className="h-full flex flex-col content-center">
		<div className="flex flex-grow justify-center mt-2">
			<div className="flex items-center">
				{instance.loading && <div className="font-bold">rendering preview...</div>}
				{instance.error && <div className="font-bold text-red-700">Unable to render the calender pdf :-(</div>}
				{!instance.loading && (
					<div>
						<div className="border border-gray-500">
						<Document file={instance.blob} onLoadSuccess={onDocumentLoadSuccess}>
							{orientation === 'portrait' && <Page height={height - 190} pageNumber={pageNumber} />}
							{orientation === 'landscape' && <Page width={width / 2} pageNumber={pageNumber} />}
						</Document>
						</div>
						<div className="grid grid-cols-3">
							<div className=""></div>
							<div className="flex justify-center">
								<div className="py-2">
									<span className="bg-white py-2 px-4 rounded shadow-lg">
										{pageNumber > 1 && <button onClick={prefPage}>&lt;</button>}
										{pageNumber === 1 && <span className="text-gray-300">&lt;</span>}
										<span className="mx-3">Page {pageNumber} of {numPages}</span>
										{pageNumber < numPages && <button onClick={nextPage}>&gt;</button>}
										{pageNumber === numPages && <span className="text-gray-300">&gt;</span>}
									</span>
								</div>
							</div>
							{rendered && <div className="text-right p-2">
								{instance.loading && <div className="bg-grey-700 text-white font-bold py-2 px-4 rounded">Download pdf</div>}
								{!instance.loading && <a href={instance.url} className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg" download="calendar.pdf">Download pdf</a>}
							</div>}
						</div>
					</div>
				)}
			</div>
		</div>
	</div>
};

export default Preview;