import { Canvas } from '@react-pdf/renderer';
import React from 'react';
import CalendarTheme from './calendarThemes';

const textWidth = (text, fontName, fontSize) => {
	const allCharacterWidths = { 
		'system-7' : { def: 3, 'M': 6, 'W': 6, 'Y': 4, '0': 4, '1': 2.5, '3': 4, '7': 4 },
		'system-10' : { def: 6, 'Monday': 36, 'Wednesday': 53, 'W#': 15, '01': 11, '02': 12, '03': 12, '04': 12, '05': 12, '06': 12, '07': 12, '08': 12, '09': 12, '10': 12, '11': 12, '12': 12, '13': 12, '14': 12, '15': 12, '16': 12, '17': 12, '18': 12, '19': 12, '20': 12, '21': 11, '22': 12, '23': 12, '24': 12, '25': 12, '26': 12, '27': 12, '28': 11, '29': 12, '30': 12, '31': 12, '#': 5, 'A': 6, 'B': 5, 'C': 6, 'F': 5, 'M': 7, 'W': 8, 'Y': 5, 'a': 5, 'r': 3, 'o': 5, 'e': 5, 'h': 5, '0': 4, '1': 5, '2': 5, '3': 5, '4': 5, '5': 5, '6': 5, '7': 6, '8': 5, '9': 5 },
		'system-35' : { def: 20, '01': 33, '02': 39, '03': 38, '04': 39, '05': 39, '06': 39, '07': 39, '08': 39, '09': 39, '10': 39, '11': 35, '12': 39, '13': 39, '14': 39, '15': 39, '16': 39, '17': 39, '18': 39, '19': 39, '20': 39, '21': 34, '22': 40, '23': 39, '24': 39, '25': 39, '26': 39, '27': 40, '28': 39, '29': 39, '30': 39, '31': 36, '#': 20, 'A': 22, 'B': 22, 'C': 24, 'F': 18, 'M': 24, 'W': 32, 'Y': 24, 'a': 18, 'r': 12, 'o': 18, 'e': 16, 'h': 16, '0': 19, '1': 17, '2': 20, '3': 18, '4': 22, '5': 22, '6': 18, '7': 18, '8': 18, '9': 18 }
	};

	const characterWidths = allCharacterWidths[`system-${fontSize}`];

	// Step 1: check for a full match of the string:
	if (Object.keys(characterWidths).includes(text)) {
		return characterWidths[text];
	}

	// Step 2: check lette for letter
	let counter = str => {
		return str.split('').reduce((total, letter) => {
			total[letter] ? total[letter]++ : total[letter] = 1;
			return total;
		}, {});
	};

	const occurences = counter(text);
	const len = Object.keys(occurences).reduce((acc, key) => {
		const width = characterWidths[key];
		return acc + (width ? width * occurences[key] : characterWidths['def']);
	}, 0);
	//console.log(text, occurences, len, characterWidths);
	return len;
};

const textHeight = (text, fontName, fontSize) => {
	const key = `system-${fontSize}`;

	switch(key) {
		case 'system-7': return 7;
		case 'system-10': return 13;
		case 'system-35': return 35;
		default: return fontSize;
	}
};

const getWeek = (inputDate) => {
	var date = new Date(inputDate.getTime());
	date.setHours(0, 0, 0, 0);
	// Thursday in current week decides the year.
	date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
	// January 4 is always in week 1.
	var week1 = new Date(date.getFullYear(), 0, 4);
	// Adjust to Thursday in week 1 and count number of weeks from date to week1.
	return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
		- 3 + (week1.getDay() + 6) % 7) / 7);
}

const getNumberOfDaysInMonth = (date) => {
	return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(); // we intentionaly use the next month with the zero day, this gives the last day of the previous month
}

const formatNumber = (value, prefixZero) => {
	return prefixZero ? value.toString().padStart(2, '0') : value.toString();
}

const setupWeekdDayHeader = (firstDayOfWeek, weekDays) => {
	// firstDayOfWeek 0 = Sun 6 = Sa

	let wd = weekDays;
	if (typeof weekDays === 'undefined') {
		wd = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
	}

	if (firstDayOfWeek === 0) {
		return wd;
	}

	return wd.slice(firstDayOfWeek).concat(wd.slice(0, firstDayOfWeek));
}

const cellOffsetForStartOfWeekDay = (startOfWeekdDay) => {
	switch (startOfWeekdDay) {
		case 1: // Start calendar weeks on Monday
			return 6;
		case 2:
			return 5;
		case 3:
			return 4;
		case 4:
			return 3;
		case 5:
			return 2;
		case 6:
			return 1;
		default:
			return 0; // Start calendar weeks on Sundays
	}
};

const drawGrid = (e, rowCount, cellW, isoColumnWidth, columnCount, cellH, dayRowHeight, width, height, gridColor, highlightedColumnsColor, monthRowbackgroundColor, dayRowbackgroundColor, startOfWeekDay, ommitMonthHeader, ommitDayHeader, ommitIsoColumn, date) => {
	const yOffset = ommitMonthHeader ? 0 : cellH;
	
	if (!ommitMonthHeader && monthRowbackgroundColor !== null) {
		e.rect(0, 0, width, cellH).fill(monthRowbackgroundColor);
	}

	if (!ommitDayHeader && dayRowbackgroundColor !== null) {
		e.rect(0, yOffset, width, dayRowHeight).fill(dayRowbackgroundColor);
	}

	let xPos = 0;
	for (let x = 0; x < columnCount; x++) {
		let colorIndex;
		if (ommitIsoColumn) {
			colorIndex = (x + startOfWeekDay) % 7 + 1; // convert to right index in color array sunday color is allways at pos 1 and sat on 7
		} else {
			colorIndex = x === 0 ? 0 : (x -1 + startOfWeekDay) % 7 + 1; // convert to right index in color array sunday color is allways at pos 1 and sat on 7
		}
		
		if (highlightedColumnsColor[colorIndex] === null) {
			e.fill('yellow'); // back to default color;
			xPos += x === 0 ? isoColumnWidth : cellW;
			continue;
		}

		e.rect(xPos, yOffset, x === 0 ? isoColumnWidth : cellW, height - yOffset).fill(highlightedColumnsColor[colorIndex]);
		xPos += x === 0 ? isoColumnWidth : cellW;
	}

	if (gridColor === null) {
		return;
	}

	// 2023-11-08 gridColor used to be a simple string but now it is an object
	// If a string is given we need to create the the expected object ourselfs
	let gridConfig = gridColor;
	if (typeof gridColor === 'string') {
		gridConfig = defaultGridConfig(gridColor);
	}

	const cellShiftOffset = (date.getDay() + cellOffsetForStartOfWeekDay(startOfWeekDay)) % 7; // answers how many cells the first day of the month is shifted to the right in the first row
	const numberOfDaysInMonth = getNumberOfDaysInMonth(date);
	
	// Draw Vertical Lines
	xPos = 0;
	const lastLineIndex = ommitIsoColumn ? 7 : 8;
	const verticalLines = ommitIsoColumn ? gridConfig.verticalLinesEightColumnLayout : gridConfig.verticalLinesNineColumnLayout;
	for (let x = 0; x <= columnCount; x++) {
		const color = verticalLines.colors[x];

		// The y start position of the lines can be customized.
		// The offset is counted in number of lines and we need to account for the variable height of the first row (the day row!).
		// Using a value like 1.5 will shift the start one full cell plus half of the next cell down
		//
		// 0 -----------------------
		//  |         Month         |
		// 1 -----------------------
		//  |  W  |  Mo |  Di | ... |
		// 2 -----------------------
		//  |  x  |  x  |  x  | ... |
		//
		// The first and last vertical line are special and can go up to the month header
		// so the default for them is 0 for the other the default value is 1

		let topOffsetValue = verticalLines.topOffset[x];
		if (verticalLines.topOffset[x] > 0) {
			topOffsetValue = verticalLines.topOffset[x] - (verticalLines.topOffset[x] > 1 && ommitDayHeader ? 1 : 0);
			if ((x > 0 && x < lastLineIndex) || ommitMonthHeader) {
				topOffsetValue = verticalLines.topOffset[x] - 1 - (verticalLines.topOffset[x] > 1 && ommitDayHeader ? 1 : 0);
			}
		}
		
		const topOffset = topOffsetValue === 0 ? 0 : topOffsetValue <= 1 ? dayRowHeight * topOffsetValue : dayRowHeight + (topOffsetValue - 1) * cellH;
		const dayX = ommitIsoColumn ? (x === 0 ? 0 : x - 1) : (x <= 1 ? 0 : x - 2); // the actual cell index corrected for the first column used for the week
		const secondToLastDayRowCellDay = 4 * 7 + dayX + 1 - cellShiftOffset; // check which day of the month is placed in the second to last row (4) on column dayX
		const lastDayRowCellDay = 5 * 7 + dayX + 1 - cellShiftOffset; // the same but for the last row (5)
		let bottomInset = 0;
		if (verticalLines.bottomInsetWhenEmpty && lastDayRowCellDay > numberOfDaysInMonth) {
			if (secondToLastDayRowCellDay <= numberOfDaysInMonth) { // if if the second to last row is filled at the current x pos
				bottomInset = cellH;
			} else { // it is not filled so we need to inset the lines twice the row height from the bottom
				bottomInset = 2 * cellH;
			}
		}
		
		if (color !== null) {
			e.strokeColor(color);
			e.moveTo(xPos, (x > 0 && x < lastLineIndex ? yOffset : 0) + topOffset).lineTo(xPos, height - bottomInset).stroke();
		}
		
		xPos += x === 0 ? isoColumnWidth : cellW;
	}

	// Draw Horizontal Lines
	let yPos = 0;
	const lineWithDifferentHeight = ommitMonthHeader ? 0 : 1;
	
	let horizontalLines;
	if (ommitMonthHeader) {
		if (ommitDayHeader) {
			horizontalLines = gridConfig.horizontalLines.noHeaderRow;
		} else {
			horizontalLines = gridConfig.horizontalLines.weekOnlyHeaderRow;
		}
	} else {
		if (ommitDayHeader) {
			horizontalLines = gridConfig.horizontalLines.monthOnlyHeaderRow;
		} else {
			horizontalLines = gridConfig.horizontalLines.monthAndWeekHeaderRow;
		}
	}
	
	for (let y = 0; y < rowCount + 1; y++) {
		const color = horizontalLines.colors[y];

		// The x start position of the lines can be customized.
		// The same logic as above see there
		// The value needs to be adjusted depending on wheather the first column is visible (iso week)
		const leftOffsetValue = horizontalLines.leftOffset[y] - (horizontalLines.leftOffset[y] > 0 && ommitIsoColumn ? 1 : 0);
		const leftOffset = leftOffsetValue === 0 ? 0 : leftOffsetValue <= 1 ? isoColumnWidth * leftOffsetValue : isoColumnWidth + (leftOffsetValue - 1) * cellW;
		if (color !== null) {
			e.strokeColor(color);
			e.moveTo(leftOffset, yPos).lineTo(width, yPos).stroke();
		}
		yPos += y === lineWithDifferentHeight ? dayRowHeight : cellH;
	}

	const cellNumber = numberOfDaysInMonth + cellShiftOffset;
	// When using this feature you should not set a regular gridLine color for the two last lines in the array!
	// It would interfear with this line:
	if (gridConfig.horizontalLines.lastHorizontalLineFollowsFilledCellsColor) {
		const lastContentRow = Math.floor(cellNumber / 7);
		// we calculate it from the bottom as we then don't need special handling depending on header rows visibility / height
		yPos = height - (5 - lastContentRow) * cellH
		
		e.strokeColor(gridConfig.horizontalLines.lastHorizontalLineFollowsFilledCellsColor);

		if (cellNumber % 7 === 0) {
			// We do not need the vertical part as the last line is fully filled
			// but for consistency sake we stil draw a last line in the right color.
			// the yPos needs to be reduced by one cell height in this case!
			e.moveTo(0, yPos - cellH).lineTo(width, yPos - cellH).stroke();
		} else {
			// The last line should follow the shape of the filled cells:
			// -----------------
			// | X | X | x | X |
			//         ---------
			// | Y | Y |
			// --------
			//    (A)      (B)

			const xPosOfDirChange = width - (7 - (cellNumber % 7)) * cellW; // caluclating from the right (makes it simpler ;-)
			e.moveTo(0, yPos).lineTo(xPosOfDirChange, yPos).stroke(); // Part A
			e.moveTo(xPosOfDirChange, yPos).lineTo(xPosOfDirChange, yPos - cellH).stroke(); // Vertical Part
			e.moveTo(xPosOfDirChange, yPos - cellH).lineTo(width, yPos - cellH).stroke(); // Part B
		}
		
	}
	
};

const defaultGridConfig = (hexColor) => {
	return {
		verticalLinesNineColumnLayout: {
			topOffset: new Array(9).fill(0),
			colors: new Array(9).fill(hexColor),
			bottomInsetWhenEmpty: false,
		},
		verticalLinesEightColumnLayout: {
			topOffset: new Array(8).fill(0),
			colors: new Array(8).fill(hexColor),
			bottomInsetWhenEmpty: false,
		},
		horizontalLines: {
			lastHorizontalLineFollowsFilledCellsColor: null,
			monthAndWeekHeaderRow: {
				leftOffset: new Array(9).fill(0),
				colors: new Array(9).fill(hexColor)
			},
			weekOnlyHeaderRow: {
				leftOffset: new Array(8).fill(0),
				colors: new Array(8).fill(hexColor)
			},
			monthOnlyHeaderRow: {
				leftOffset: new Array(8).fill(0),
				colors: new Array(8).fill(hexColor)
			},
			noHeaderRow: {
				leftOffset: new Array(7).fill(0),
				colors: new Array(7).fill(hexColor)
			},
		}
	};
};

const drawMonthHeader = (e, date, width, cellH, textColor, style, prependZero, fontSize, cellAlignment) => {
	let monthName = date.toLocaleString('default', { month: style });
	if (style === 'numeric') {
		monthName = formatNumber(monthName, prependZero);
	}

	const w = textWidth(monthName, null, fontSize);
	const h = textHeight(monthName, null, fontSize);
	const labelPositionOffset = calculateLabelOffset(cellAlignment.horizontal, cellAlignment.vertical, width, cellH, w, h);
	// when the vertical alignment is middle then we need a small correction factor to make it look better in this case.
	e.fillColor(textColor).fontSize(fontSize).text(monthName, labelPositionOffset.x, labelPositionOffset.y + (cellAlignment.vertical === 'middle' ? 1 : 0));
	console.log('XXXX', e.fillColor(textColor).fontSize(fontSize));
	console.log('XXXX', e.fillColor(textColor).fontSize(fontSize), e.fillColor(textColor).fontSize(fontSize).widthOfString(monthName), e.fillColor(textColor).fontSize(fontSize).heightOfString(false), e.fillColor(textColor).fontSize(fontSize).heightOfString(true));
};

const drawDayHeader = (e, yOffset, cellW, isoColumnWidth, cellH, startOfWeekDay, highlightedColumnsTextColor, textColor, fontSize, ommitIsoColumn, cellAlignment, weekAlignment, allWeekDays, hideWeekLabel) => {
	const weekDays = ommitIsoColumn ? setupWeekdDayHeader(startOfWeekDay, allWeekDays) : [hideWeekLabel ? '': 'W#'].concat(setupWeekdDayHeader(startOfWeekDay, allWeekDays));
	const colCount = ommitIsoColumn ? 7 : 8;
	let xp = 0;
	for (var x = 0; x < colCount; x++) {
		const colorIndex = x === 0 ? 0 : (x - 1 + startOfWeekDay) % 7 + 1; // convert to right index in color array sunday color is always at pos 1 and sat on 7
		const c = highlightedColumnsTextColor[colorIndex] === null ? textColor : highlightedColumnsTextColor[colorIndex];
		const w = textWidth(weekDays[x], null, fontSize);
		const h = textHeight(weekDays[x], null, fontSize);
		const ca = !ommitIsoColumn && x === 0 ? weekAlignment : cellAlignment;
		const labelPositionOffset = calculateLabelOffset(ca.horizontal, ca.vertical, x === 0 ? isoColumnWidth : cellW, cellH, w, h);
		e.fillColor(c).fontSize(fontSize).text(weekDays[x], xp + labelPositionOffset.x, yOffset + labelPositionOffset.y);
		xp += x === 0 ? isoColumnWidth : cellW;
	}
};

const drawDays = (e, date, startOfWeekDay, prependZero, xOffset, yOffset, cellW, isoColumnWidth, cellH, highlightedColumnsTextColor, textColor, fontSize, cellDecorations, weekCellAlignment, dayCellAlignment, ommitIsoColumn) => {
	const firstDayOfMonth = (date.getDay() + cellOffsetForStartOfWeekDay(startOfWeekDay)) % 7; // 0 == Su
	const numberOfDaysInMonth = getNumberOfDaysInMonth(date);
	const topOffset = yOffset + 4;
	let lastY = -1;
	for (let i = 0; i < numberOfDaysInMonth; i++) {
		let y = Math.floor((firstDayOfMonth + i) / 7)
		let xx = (firstDayOfMonth + i) >= 7 ? (firstDayOfMonth + i) - (y * 7) : (firstDayOfMonth + i);
		let x = xOffset + xx * cellW;

		if (!ommitIsoColumn && y !== lastY) {
			const date1 = new Date(date.getFullYear(), date.getMonth(), i + 1);
			const weekInYear = getWeek(date1);
			const wiy = formatNumber(weekInYear, prependZero);
			const w = textWidth(wiy, null, fontSize);
			const h = textHeight(wiy, null, fontSize);
			const wc = highlightedColumnsTextColor[0] === null ? textColor : highlightedColumnsTextColor[0];

			const labelPositionOffset = calculateLabelOffset(weekCellAlignment.horizontal, weekCellAlignment.vertical, isoColumnWidth, cellH, w, h);
			e.fillColor(wc).fontSize(fontSize).text(wiy, labelPositionOffset.x, y * cellH + topOffset + labelPositionOffset.y);
		}
		lastY = y;
		const dayOfMonth = formatNumber(i + 1, prependZero);
		const w = textWidth(dayOfMonth, null, fontSize);
		const h = textHeight(dayOfMonth, null, fontSize);
		const colorIndex = x === 0 ? 0 : (xx + startOfWeekDay) % 7 + 1; // convert to right index in color array sunday color is always at pos 1 and sat on 7
		const c = highlightedColumnsTextColor[colorIndex] === null ? textColor : highlightedColumnsTextColor[colorIndex];
		
		const labelPositionOffset = calculateLabelOffset(dayCellAlignment.horizontal, dayCellAlignment.vertical, cellW, cellH, w, h);
		drawCellBackgroundAndForeground(e, x, y * cellH + yOffset, cellW, cellH, x + labelPositionOffset.x, y * cellH + topOffset + labelPositionOffset.y, i+ 1, dayOfMonth, fontSize, c, cellDecorations);
	}
};

const calculateLabelOffset = (horizontalAlignment, verticalAlignment, cellWidth, cellHeight, labelWidth, labelHeight) => {
	let x = 0;
	let y = 0;

	switch(horizontalAlignment) {
		case 'left':
			x = 1;
		break;
		case 'right':
			x = cellWidth - labelWidth - 1;
		break;
		default: // center
			x = (cellWidth - labelWidth) / 2;
	}

	switch(verticalAlignment) {
		case 'top':
			y = 1;
		break;
		case 'bottom':
			y = cellHeight - labelHeight - 1;
		break;
		default: // middle
			y = (cellHeight - labelHeight) / 2;
	}

	return { x: x, y: y };
}

const drawCellBackgroundAndForeground = (e, cellLeftEdge, cellTopEdge, cellW, cellH, labelX, labelY, day, formatedDayLabel, labelFontSize, labelColor, cellDecorations) => {
	// Important cellLeftEdge and cellTopEdge start on the position of the grid line add one if you
	// want to keep the grid lines visible (and subtract 2 from the cellW and cellH)
	// The cellDecorations objects look like this:
	// {
	// 	backgroundEdgeInset: { left: 1, top: 1, right: 1, bottom: '25%'}, # keys are optional or use an int instead of the object (sets all property to the same value)
	// 	backgroundFillColor: '#ff0000', # optional
	// 	backgroundStrokeColor: '#00ff00', # optional
	// 	labelColor: '#0000ff', # optional; only works in last cell decoration for a given day
	// }
	// 
	// The cellDecorations argument is an object using the day of the month as a key for the days you want to have decorations.
	// {
	//   1: <CELL_DECORATION>,
	//   X: [<CELL_DECORATION_0>,...,<CELL_DECORATION_N>]	
	// }

	function parseNumber(value, max) {
		if (typeof value === 'number') {
			return value;
		}

		// assume string value
		if (value.endsWith('%')) {
			const v = value.substring(0, value.length - 1);
			const vv = parseInt(v, 10);
			return Math.floor(max * vv / 100);
		}

		console.warn('Unkown number format for parseNumber method: ' + value);
		return 0;
	}

	if (day in cellDecorations ) {
		// We can have multiple decoration for a given day.
		let cellDecorationItems = cellDecorations[day];
		if (!Array.isArray(cellDecorations[day])) {
			cellDecorationItems = [cellDecorations[day]];
		}

		for (let index = 0; index < cellDecorationItems.length; index++) {
			const cellDecoration = cellDecorationItems[index];
			
			const backgroundFillColor = cellDecoration['backgroundFillColor'];
			const backgroundStrokeColor = cellDecoration['backgroundStrokeColor'];

			const edgeInset = cellDecoration['edgeInset'];
			let leftEdgeInset = 0;
			let topEdgeInset = 0;
			let rightEdgeInset = 0;
			let bottomEdgeInset = 0;
			if (edgeInset && typeof edgeInset === 'number') {
				leftEdgeInset = parseNumber(edgeInset, cellW);
				topEdgeInset = parseNumber(edgeInset, cellH);
				rightEdgeInset = parseNumber(edgeInset, cellW);
				bottomEdgeInset = parseNumber(edgeInset, cellH);
			} else if (edgeInset) {
				leftEdgeInset = parseNumber(edgeInset['left'] ?? 0, cellW);
				topEdgeInset = parseNumber(edgeInset['top'] ?? 0, cellH);
				rightEdgeInset = parseNumber(edgeInset['right'] ?? 0, cellW);
				bottomEdgeInset = parseNumber(edgeInset['bottom'] ?? 0, cellH);
			}
			
			e.rect(cellLeftEdge + leftEdgeInset, cellTopEdge + topEdgeInset, cellW - leftEdgeInset - rightEdgeInset, cellH - topEdgeInset - bottomEdgeInset)

			if (backgroundFillColor && backgroundStrokeColor) {
				e.fillAndStroke(backgroundFillColor, backgroundStrokeColor);
			} else if (backgroundFillColor) {
				e.fillAndStroke(backgroundFillColor, backgroundFillColor);
			} else if (backgroundStrokeColor) {
				e.stroke(backgroundStrokeColor);
			}
		}

		// Main label can only be configured by the last decoration!
		const lc = cellDecorationItems[cellDecorationItems.length - 1]['labelColor'] ?? labelColor;
		e.fillColor(lc).fontSize(labelFontSize).text(formatedDayLabel, labelX, labelY);

		return;
	}
	
	e.fillColor(labelColor).fontSize(labelFontSize).text(formatedDayLabel, labelX, labelY);
}

const drawTrailingCellsCover = (e, date, width, height, xOffset, yOffset, cellW, cellH, startOfWeekDay) => {
	const color = '#FFFFFF';
	const firstDayOfMonth = (date.getDay() + cellOffsetForStartOfWeekDay(startOfWeekDay)) % 7; // 0 == Su
	const numberOfDaysInMonth = getNumberOfDaysInMonth(date);
	const cellNumber = firstDayOfMonth + numberOfDaysInMonth;
	const cy = Math.floor(cellNumber / 7);
	const cx = cellNumber % 7;
	
	// TBD Add cover for first line (if needed -> cover weekend color from Mon to Sat)
	e.rect((cx === 0 ? -2 : xOffset) + cx * cellW + - 1, yOffset + cy * cellH + 1, Math.min((cx === 0 ? 8 : (7 - cx)) * cellW, width) + 2, cellH + 1).fillAndStroke(color, color);
	if (cellNumber <= 35 && cy >= 4) { // cover last line only when it is empty OO
		e.rect(-1, height - cellH + 1, width + 2, cellH + 1).fillAndStroke(color, color);
	}
};

/*const debugFontSizeHelper = (e, fontSize) => {
	// Helper code to mesure fonts.
	const chars = { '#': 5, 'A': 6, 'B': 5, 'C': 6, 'F': 5, 'M': 7, 'W': 8, 'Y': 5, 'a': 5, 'r': 3, 'o': 5, 'e': 5, 'h': 5, '0': 9, '1': 11, '2': 5, '3': 5, '4': 5, '5': 5, '6': 5, '7': 6, '8': 5, '9': 5 }
	
	let xOffset = 0;
	let yOffset = 0;
	Object.keys(chars).forEach((c, ii) => {

		e.fontSize(fontSize).text(c, xOffset, yOffset);

	for (var i = 0; i < 20; i++) {
		const h = i % 5 === 0 ? 40 : 30;
		e.moveTo(xOffset + i * 2, yOffset).lineTo(xOffset + i * 2, yOffset + h).stroke('black');
	}

	xOffset += 30;
	if (ii % 10 === 0) {
		xOffset = 0;
		yOffset += 50;
	}
	});
};*/

const applyTheme = (themeNameOrTheme) => {
	const themes = CalendarTheme();

	let theme = null;
	if (typeof (themeNameOrTheme) === 'object') {
		theme = themeNameOrTheme;
	} else {
		theme = themes[themeNameOrTheme];
		if (theme === undefined) {
			console.warn('Theme error: Unknown theme named ' + themeNameOrTheme + ' will use default theme!');
			return themes['default']
		}

		if (themeNameOrTheme === 'default') {
			return theme;
		}
	}

	const parentThemeName = theme['parent'];
	if (parentThemeName === undefined || parentThemeName === null) {
		console.warn('Theme error: Parent property missing will use default theme!');
		return themes['default'];
	}

	let parentTheme = themes[parentThemeName];
	if (parentTheme === undefined) {
		console.warn('Theme error: Unknown parent theme named ' + parentThemeName + ' will use default theme!');
		parentTheme = themes['default'];
	}

	return { ...parentTheme, ...theme }; // Return the combined theme
};

const drawCalendar = (e, width, height, year, month, prependZero, startOfWeekDay, themeName, fontSize, isoWeekColumnMode, dayNameRowMode, ommitMonthHeader, cellDecorations, alignments, weekDays) => {
	const dayNameRowFixedHeight = 20;
	let rowCount;
	let cellHeightRowCount;
	let dayNameRowHeight = 0;
	let ommitDayHeader = false;
	switch(dayNameRowMode) {
		case 'none':
			rowCount = 7;
			cellHeightRowCount = rowCount;
			ommitDayHeader = true;
			break;
		case 'fixed': // fixed height for weekday name row
			dayNameRowHeight = dayNameRowFixedHeight;
			rowCount = 8;
			cellHeightRowCount = rowCount - 1;
			break;
		default:
			rowCount = 8;
			cellHeightRowCount = rowCount;
	}

	if (ommitMonthHeader) {
		rowCount -= 1;
		cellHeightRowCount -= 1;
	}

	let columnCount;
	let columnCountForWidthCalc;
	let isoColumnWidth = 0;
	let ommitIsoColumn = false;
	switch(isoWeekColumnMode) {
		case 'none':
			columnCount = 7;
			columnCountForWidthCalc = columnCount;
			ommitIsoColumn = true;
			break;
		case 'fixed': // intentional fall though we reduce the height but no the count
			isoColumnWidth = dayNameRowFixedHeight;
			columnCount = 8;
			columnCountForWidthCalc = columnCount - 1;
			break;
		default:
			columnCount = 8;
			columnCountForWidthCalc = columnCount;
	}

	const cellW = (width - isoColumnWidth) / columnCountForWidthCalc;
	const cellH = (height - dayNameRowHeight) / cellHeightRowCount;
	if (dayNameRowMode !== 'fixed') {
		dayNameRowHeight = cellH;
	}

	if (isoWeekColumnMode !== 'fixed') {
		isoColumnWidth = cellW;
	}

	const {
		highlightedColumnsColor,
		highlightedColumnsTextColor,
		gridColor,
		monthRowBackgroundColor,
		dayRowBackgroundColor,
		monthHeaderTextColor,
		monthNameStyle,
		dayHeaderTextColor,
		dayTextColor,
		hideWeekLabel,
		hideTrailingCells,
	} = applyTheme(themeName);

	
	const date = new Date(year, month - 1, 1);
	drawGrid(e, rowCount, cellW, isoColumnWidth, columnCount, cellH, dayNameRowHeight, width, height, gridColor, highlightedColumnsColor, monthRowBackgroundColor, dayRowBackgroundColor, startOfWeekDay, ommitMonthHeader, ommitDayHeader, ommitIsoColumn, date);
	
	if (!ommitMonthHeader) {
		drawMonthHeader(e, date, width, cellH, monthHeaderTextColor, monthNameStyle, prependZero, fontSize, alignments.monthCell);
	}

	const weekAlignment = {
		horizontal: alignments.isoWeekCell.horizontal,
		vertical: alignments.weekNameCell.vertical
	};
	if (!ommitDayHeader) {
		const o = alignments.weekNameCell.vertical === 'top' ? 1 : 2;
		const yOffset = (ommitMonthHeader ? 0 : cellH) + o;
		drawDayHeader(e, yOffset, cellW, isoColumnWidth, dayNameRowHeight, startOfWeekDay, highlightedColumnsTextColor, dayHeaderTextColor, fontSize, ommitIsoColumn, alignments.weekNameCell, weekAlignment, weekDays, hideWeekLabel);
	}
	const xOffset = (ommitIsoColumn ? 0 : isoColumnWidth);
	const yOffset = (ommitMonthHeader ? 0 : cellH) + (ommitDayHeader ? 0 : dayNameRowHeight);
	drawDays(e, date, startOfWeekDay, prependZero, xOffset, yOffset, cellW, isoColumnWidth, cellH, highlightedColumnsTextColor, dayTextColor, fontSize, cellDecorations, alignments.isoWeekCell, alignments.contentCell, ommitIsoColumn);

	if (hideTrailingCells) {
		drawTrailingCellsCover(e, date, width, height, xOffset, yOffset, cellW, cellH, startOfWeekDay);
	}

	//debugFontSizeHelper(e, 7);
};

// Create Document Component
const MiniCalendar = (props) => {
	// valid values for dayNameRowMode or isoWeekColumnMode: fixed, equal, none
	// valid valus for alignment:
	//  - horizontal: left, center, right
	//  - vertical: top, middle, bottom
	const { width, height, year, month, prependZero, startOfWeekDay, themeName, fontSize, dayNameRowMode = 'equal', isoWeekColumnMode = 'equal', ommitMonthHeader = false, cellDecorations = {}, cellAlignments, weekDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'] } = props;
	
	let alignments = cellAlignments;
	if (typeof cellAlignments === 'undefined') {
		const a = { horizontal: 'center', vertical: 'middle' };
		alignments = {
			monthCell: a,
			weekNameCell: a,
			isoWeekCell: a,
			contentCell: a,
		};
	}

	return (<Canvas
		debug={false}
		paint={(e, w, h) => {
			drawCalendar(e, width, height, year, month, prependZero, startOfWeekDay, themeName, fontSize, isoWeekColumnMode, dayNameRowMode, ommitMonthHeader, cellDecorations, alignments, weekDays);
		}}
		style={{ width: width, height: height }}
		width={width}
		height={height}
	/>);
};

export default MiniCalendar;