import { Canvas } from "@react-pdf/renderer";

const drawCalendar = (e, width, height, firstDayOfWeekDate, numberOfDays, startTime, endTime) => {
    const LEFT_LEGEND_SPACE = 25;
    const TOP_LEGEND_SPACE = 15;
	const cellW = (width - LEFT_LEGEND_SPACE) / numberOfDays;
    const numberOfHours = endTime - startTime + 1;
    const topAndBottomSpace = 8;
	const cellH = (height - TOP_LEGEND_SPACE - 2 * topAndBottomSpace) / (numberOfHours - 1);
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const fullHourColor = '#DDDDDD';
    const quarterHourColor = fullHourColor;
    const halfHourColor = fullHourColor;
    const threeQuarterHourColor = quarterHourColor;
    for (let y = 0; y < numberOfHours; y++) {
        let yOffset = topAndBottomSpace;
        e.moveTo(LEFT_LEGEND_SPACE - 4, y * cellH + TOP_LEGEND_SPACE + yOffset).lineTo(width, y * cellH + TOP_LEGEND_SPACE + yOffset).stroke(fullHourColor);
        e.fontSize(8).text(`${(startTime+y) < 10 ? '0' : ''}${startTime +y}:00`, 0, y * cellH + TOP_LEGEND_SPACE + yOffset - 4);

        if (y === numberOfHours - 1) {
            continue;
        }
        yOffset += cellH * 0.25;
        e.moveTo(LEFT_LEGEND_SPACE, y * cellH + TOP_LEGEND_SPACE + yOffset).lineTo(width, y * cellH + TOP_LEGEND_SPACE + yOffset).stroke(quarterHourColor);
        
        yOffset += cellH * 0.25;
        e.moveTo(LEFT_LEGEND_SPACE - 4, y * cellH + TOP_LEGEND_SPACE + yOffset).lineTo(width, y * cellH + TOP_LEGEND_SPACE + yOffset).stroke(halfHourColor);
        e.fontSize(8).text(`${(startTime+y) < 10 ? '0' : ''}${startTime +y}:30`, 0, y * cellH + TOP_LEGEND_SPACE + yOffset - 4);

        yOffset += cellH * 0.25;
        e.moveTo(LEFT_LEGEND_SPACE, y * cellH + TOP_LEGEND_SPACE + yOffset).lineTo(width, y * cellH + TOP_LEGEND_SPACE + yOffset).stroke(threeQuarterHourColor);
    }

    //e.moveTo(0, TOP_LEGEND_SPACE).lineTo(width, TOP_LEGEND_SPACE).stroke('#000000');
	for (let x = 0; x <= numberOfDays -1; x++) {
        const date = new Date(firstDayOfWeekDate.getFullYear(), firstDayOfWeekDate.getMonth(), firstDayOfWeekDate.getDate() + x);
        
        if (x > 0) {
            e.moveTo(x * cellW + LEFT_LEGEND_SPACE, TOP_LEGEND_SPACE - 4).lineTo(x * cellW + LEFT_LEGEND_SPACE, height).stroke('#000000');
        }
        
        if (x === numberOfDays) {
            continue;
        }

        //e.fontSize(15).text(`${weekDays[date.getDay()]} ${date.toLocaleDateString("en-US", { day: '2-digit' })}`, x * cellW + LEFT_LEGEND_SPACE + 5, 0);
        e.fontSize(8).text(`${weekDays[date.getDay()]} ${date.toLocaleDateString("en-US", { day: '2-digit', month: '2-digit' })}`, x * cellW + LEFT_LEGEND_SPACE + 18, 0);
    }
};

const WeekCalendar = (props) => {
	const { width, height, firstDayofWeekDate, numberOfDays, startTime, endTime } = props;

	return (<Canvas
		debug={false}
		paint={(e, w, h) => {
			drawCalendar(e, width, height, firstDayofWeekDate, numberOfDays, startTime, endTime);
		}}
		style={{ width: width, height: height }}
		width={width}
		height={height}
	/>);
};

export default WeekCalendar;