
function CalendarTheme() {

	return {
		'default': {
			displayName: 'Default (Yellow)',
			parent: 'default',
			highlightedColumnsColor: [
				'#EEE', // iso Week
				'#F9D835', // Sunday
				null,
				null,
				null,
				null,
				null,
				'#F9D835' // Saturday
			],
			highlightedColumnsTextColor: [
				null, // iso Week
				null, // Sunday
				null,
				null,
				null,
				null,
				null,
				null  // Saturday
			],
			gridColor: '#000', // or null for no grid / or object to color induvidual lines (check the modern theme for an example)
			monthRowBackgroundColor: null,
			monthHeaderTextColor: '#000',
			dayRowBackgroundColor: '#EEE',
			monthNameStyle: 'long', // one of short, long, numeric
			dayHeaderTextColor: '#000',
			dayTextColor: '#000',
			hideWeekLabel: false,
			hideTrailingCells: false,
		},
		'defaultGreen': {
			displayName: 'Default (Green)',
			parent: 'default',
			dayRowBackgroundColor: '#a3b18a',
			highlightedColumnsColor: [
				'#a3b18a', // iso Week
				'#588157', // Sunday
				null,
				null,
				null,
				null,
				null,
				'#588157' // Saturday
			],
		},
		'defaultBlue': {
			displayName: 'Default (Blue)',
			parent: 'default',
			dayRowBackgroundColor: '#caf0f8',
			highlightedColumnsColor: [
				'#caf0f8', // iso Week
				'#48cae4', // Sunday
				null,
				null,
				null,
				null,
				null,
				'#48cae4' // Saturday
			],
		},
		'noGridYellow': {
			displayName: 'No Grid Lines (Yellow)',
			parent: 'default',
			gridColor: null,
			hideTrailingCells: true,
		},
		'noGridGreen': {
			displayName: 'No Grid Lines (Green)',
			parent: 'default',
			gridColor: null,
			hideTrailingCells: true,
			dayRowBackgroundColor: '#a3b18a',
			highlightedColumnsColor: [
				'#a3b18a', // iso Week
				'#588157', // Sunday
				null,
				null,
				null,
				null,
				null,
				'#588157' // Saturday
			],
		},
		'noGridBlue': {
			displayName: 'No Grid Lines (Blue)',
			parent: 'default',
			gridColor: null,
			hideTrailingCells: true,
			dayRowBackgroundColor: '#caf0f8',
			highlightedColumnsColor: [
				'#caf0f8', // iso Week
				'#48cae4', // Sunday
				null,
				null,
				null,
				null,
				null,
				'#48cae4' // Saturday
			],
		},
		'noWeekendHighlight': {
			displayName: 'No Weekend Day Highlights',
			parent: 'default',
			highlightedColumnsColor: [
				'#EEE', // iso Week
				null, // Sunday
				null,
				null,
				null,
				null,
				null,
				null // Saturday
			],
		},
		'noWeekendHighlightNoGrid': {
			displayName: 'No Weekend Day Highlights (No Grid)',
			parent: 'default',
			gridColor: null,
			hideTrailingCells: true,
			highlightedColumnsColor: [
				'#EEE', // iso Week
				null, // Sunday
				null,
				null,
				null,
				null,
				null,
				null // Saturday
			],
		},
		'modern': {
			displayName: 'Modern',
			parent: 'default',
			highlightedColumnsColor: [
				null, //'#EFE9EA', // iso Week
				null, // Sunday
				null,
				null,
				null,
				null,
				null,
				null // Saturday
			],
			highlightedColumnsTextColor: [
				'#AFA9AA', // iso Week
				'#706A6B', // Sunday
				null,
				null,
				null,
				null,
				null,
				'#706A6B'  // Saturday
			],
			gridColor: { // or null for no grid / or object to color induvidual lines
				verticalLinesNineColumnLayout: { // 9 items needed
					topOffset: [0, 2, 0, 0, 0, 0, 0, 0, 0], // max value is 7
					colors: [null, '#D5D0D1', null, null, null, null, null, null, null], // with iso week column visible
					bottomInsetWhenEmpty: true
				},
				verticalLinesEightColumnLayout: { // 8 items needed
					topOffset: [0, 0, 0, 0, 0, 0, 0, 0], // max value is 7
					colors: [null, null, null, null, null, null, null, null], // no iso column visible
					bottomInsetWhenEmpty: true
				},
				horizontalLines: {
					lastHorizontalLineFollowsFilledCellsColor: null, // (see [[20231109062252]] 🌅 Morning Pages No: 479)
					monthAndWeekHeaderRow: { // 9 items needed
						leftOffset: [0, 0, 0, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, null, null, null, null, null, null, null, null],
					},
					weekOnlyHeaderRow: { // 8 items needed
						leftOffset: [0, 0, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, null, null, null, null, null, null, null],
					},
					monthOnlyHeaderRow: { // 8 items needed
						leftOffset: [0, 0, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, null, null, null, null, null, null, null],
					},
					noHeaderRow: { // 7 items needed
						leftOffset: [0, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, null, null, null, null, null, null],
					},
				}
			},
			monthRowBackgroundColor: null,
			monthHeaderTextColor: '#706A6B',
			dayRowBackgroundColor: null,
			monthNameStyle: 'long', // one of short, long, numeric
			dayHeaderTextColor: '#706A6B',
			dayTextColor: '#403A3B',
			hideWeekLabel: true,
			hideTrailingCells: false,
		},
		'modern2': {
			displayName: 'Modern 2',
			parent: 'modern',
			gridColor: { // or null for no grid / or object to color induvidual lines
				verticalLinesNineColumnLayout: { // 9 items needed
					topOffset: [0, 2, 0, 0, 0, 0, 0, 0, 0], // max value is 7
					colors: [null, '#D5D0D1', null, null, null, null, null, null, null], // with iso week column visible
					bottomInsetWhenEmpty: true
				},
				verticalLinesEightColumnLayout: { // 8 items needed
					topOffset: [0, 0, 0, 0, 0, 0, 0, 0], // max value is 7
					colors: [null, null, null, null, null, null, null, null], // no iso column visible
					bottomInsetWhenEmpty: true
				},
				horizontalLines: {
					monthAndWeekHeaderRow: { // 9 items needed
						leftOffset: [0, 0, 1, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, null, '#D5D0D1', null, null, null, null, null, null],
					},
					weekOnlyHeaderRow: { // 8 items needed
						leftOffset: [0, 1, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, '#D5D0D1', null, null, null, null, null, null],
					},
					monthOnlyHeaderRow: { // 8 items needed
						leftOffset: [0, 0, 1, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, '#FF00D1', null, null, null, null, null, null],
					},
					noHeaderRow: { // 7 items needed
						leftOffset: [0, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, null, null, null, null, null, null],
					},
				}
			}
		},
		'modern3': {
			displayName: 'Modern 3',
			parent: 'modern',
			gridColor: { // or null for no grid / or object to color induvidual lines
				verticalLinesNineColumnLayout: { // 9 items needed
					topOffset: [2, 0, 0, 0, 0, 0, 0, 0, 2], // max value is 7
					colors: ['#D5D0D1', null, null, null, null, null, null, null, '#D5D0D1'], // with iso week column visible
					bottomInsetWhenEmpty: true
				},
				verticalLinesEightColumnLayout: { // 8 items needed
					topOffset: [2, 0, 0, 0, 0, 0, 0, 2], // max value is 7
					colors: ['#D5D0D1', null, null, null, null, null, null, '#D5D0D1'], // no iso column visible
					bottomInsetWhenEmpty: true
				},
				horizontalLines: {
					monthAndWeekHeaderRow: { // 9 items needed
						leftOffset: [0, 0, 0, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, null, null, null, null, null, null, null, null],
					},
					weekOnlyHeaderRow: { // 8 items needed
						leftOffset: [0, 0, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, null, null, null, null, null, null, null],
					},
					monthOnlyHeaderRow: { // 8 items needed
						leftOffset: [0, 0, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, null, null, null, null, null, null, null],
					},
					noHeaderRow: { // 7 items needed
						leftOffset: [0, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, null, null, null, null, null, null],
					},
				}
			},
			hideWeekLabel: false,
		},
		'modern4': {
			displayName: 'Modern 4',
			parent: 'modern',
			gridColor: { // or null for no grid / or object to color induvidual lines
				verticalLinesNineColumnLayout: { // 9 items needed
					topOffset: [0, 0, 0, 0, 0, 0, 0, 0, 0], // max value is 7
					colors: [null, null, null, null, null, null, null, null, null], // with iso week column visible
					bottomInsetWhenEmpty: true
				},
				verticalLinesEightColumnLayout: { // 8 items needed
					topOffset: [0, 0, 0, 0, 0, 0, 0, 0], // max value is 7
					colors: [null, null, null, null, null, null, null, null], // no iso column visible
					bottomInsetWhenEmpty: true
				},
				horizontalLines: {
					monthAndWeekHeaderRow: { // 9 items needed
						leftOffset: [0, 0, 0, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, null, '#D5D0D1', null, null, null, null, null, null],
					},
					weekOnlyHeaderRow: { // 8 items needed
						leftOffset: [0, 0, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, '#D5D0D1', null, null, null, null, null, null],
					},
					monthOnlyHeaderRow: { // 8 items needed
						leftOffset: [0, 0, 1, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, '#FF00D1', null, null, null, null, null, null],
					},
					noHeaderRow: { // 7 items needed
						leftOffset: [0, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, null, null, null, null, null, null],
					},
				}
			}
		},
		'modern5': {
			displayName: 'Modern 5',
			parent: 'modern',
			gridColor: { // or null for no grid / or object to color induvidual lines
				verticalLinesNineColumnLayout: { // 9 items needed
					topOffset: [0, 0, 0, 0, 0, 0, 0, 0, 0], // max value is 7
					colors: [null, null, null, null, null, null, null, null, null], // with iso week column visible
					bottomInsetWhenEmpty: true
				},
				verticalLinesEightColumnLayout: { // 8 items needed
					topOffset: [0, 0, 0, 0, 0, 0, 0, 0], // max value is 7
					colors: [null, null, null, null, null, null, null, null], // no iso column visible
					bottomInsetWhenEmpty: true
				},
				horizontalLines: {
					lastHorizontalLineFollowsFilledCellsColor: '#D5D0D1',
					monthAndWeekHeaderRow: { // 9 items needed
						leftOffset: [0, 0, 0, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, null, '#D5D0D1', null, null, null, null, null, null],
					},
					weekOnlyHeaderRow: { // 8 items needed
						leftOffset: [0, 0, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, '#D5D0D1', null, null, null, null, null, null],
					},
					monthOnlyHeaderRow: { // 8 items needed
						leftOffset: [0, 0, 1, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, '#FF00D1', null, null, null, null, null, null],
					},
					noHeaderRow: { // 7 items needed
						leftOffset: [0, 0, 0, 0, 0, 0, 0], // max value is 7
						colors: [null, null, null, null, null, null, null],
					},
				}
			}
		},
	};
};

export default CalendarTheme;