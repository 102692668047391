import { useState } from "react";

export const CustomizeTheme = (props) => {
    const { closeModal, refreshPreview, data } = props;

    const [customTheme, setCustomTheme] = useState(data);
    const customThemeValid = () => {
        try {
            JSON.parse(customTheme);
            return true;
        } catch { }

        return false;
    }

    const updateWithCustomTheme = () => {
        try {
            const theme = JSON.parse(customTheme);

            refreshPreview(theme);
        } catch {
            console.error('could not parse theme');
        }
    };


    return <div className="fixed z-50">
        <div id="overlay" className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"></div>

        <div id="dialog"
            className="fixed z-50  w-1/2 bg-white rounded-md px-8 py-6 space-y-5 drop-shadow-lg">
            <h1 className="text-2xl font-semibold">Customize Theme</h1>
            <div className="py-5 border-t border-b border-gray-300">
                <div className=" flex flex-col flex-grow">
                    <textarea htmlFor="customTheme" className="border h-96 flex-grow py-2 px-3 text-grey-darkest" value={customTheme} onChange={e => setCustomTheme(e.target.value)}></textarea>
                    <div className="my-2">
                        {customThemeValid() && (<button type="submit" onClick={updateWithCustomTheme} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" >Update Theme</button>)}
                        {!customThemeValid() && (<button type="submit" className="bg-gray-500 text-white font-bold py-2 px-4 rounded" >Update Theme</button>)}
                    </div>
                </div>
                <div className="text-xs">
                    <p>The properties <b>highlightedColumnsColor</b> and <b>highlightedColumnsTextColor</b> are arrays with color codes:</p>
                    <pre>{JSON.stringify([
                        '<Iso week color>',
                        '<Sunday color>',
                        '<Monday color>',
                        '<Tuesday color>',
                        '<Wednesday color>',
                        '<Thursday color>',
                        '<Friday color>',
                        '<Saturday color>'
                    ], null, ' ')}</pre>
                    <p>The value null is also allowed to set no color.</p>
                    <p>The property <b>monthNameStyle</b> expects one of the following values: short, long, numeric</p>
                    <p className="mt-1">Press the <b>Update Theme</b> button to apply the changes.</p>
                    <p className="text-red-600">The changes will not be persisted on page reload or theme change!</p>
                </div>
            </div>
            <div className="flex justify-end">
                <button onClick={() => closeModal()} className="px-5 py-2 bg-indigo-500 hover:bg-indigo-700 text-white cursor-pointer rounded-md">
                    Close</button>
            </div>
        </div>
    </div>
};