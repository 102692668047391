import { Document, StyleSheet } from '@react-pdf/renderer';
import React from 'react';
import { YearPerPageThreeColumnLayout } from './YearPerPageThreeColumnLayout.js';

// Create styles
const styles = StyleSheet.create({
	page: {
		marginLeft: '0cm',
		marginRight: '0cm'
	},
	section: {
		marginRight: '1cm',
	}
});

const YearPerPageUSLetter = (props) => {
	const { years, prependZero, startWeekOnSundays, showWeekColumn, themeName, orientation, customMarkings, pageTitleTemplateParser } = props;

	const xMax = orientation === 'portrait' ? 3 : 4;
	const yMax = orientation === 'portrait' ? 4 : 3;

	return <Document>
		{years.map((yearConfig) =>
		(<YearPerPageThreeColumnLayout
			key={'year-' + yearConfig.year}
			pageTitleTemplateParser={pageTitleTemplateParser}
			yearConfig={yearConfig}
			orientation={orientation}
			styles={styles}
			xMax={xMax} yMax={yMax}
			customMarkings={customMarkings}
			prependZero={prependZero}
			startWeekOnSundays={startWeekOnSundays}
			showWeekColumn={showWeekColumn}
			themeName={themeName}

			pageSize="LETTER"
			titleMarginVertical='1cm'
			contentMarginLeft='2cm'
			contentMarginRight='2cm'
			miniCalendarWidth={156}
			miniCalendarHeight={125}
			fontSize={10}
			miniCalendarMarginBottom='1.5cm'
			footerLeftMargin={0}
			footerRightMargin='2cm'
			footerBottomMargin='5mm'
			footerFontSize={10}
		/>))}
	</Document>
};

export default YearPerPageUSLetter;
