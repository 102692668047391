import { ThemeListContainer } from "./ThemeListContainer";

export const ThemeSelector = (props) => {
    const { closeModal, refreshPreview, themes, configuration, onThemeSelected } = props;

    const onSelectTheme = (themeKey) => {
        onThemeSelected(themeKey);
        refreshPreview();
    };

    return <div className="fixed z-50">
        <div id="overlay" className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"></div>

        <div id="dialog"
            className="fixed z-50  w-1/2 bg-white rounded-md px-8 py-6 space-y-5 drop-shadow-lg">
            <h1 className="text-2xl font-semibold">Select Theme</h1>
            <div style={{ 'minHeight': '580px', 'maxHeight': '70vh', 'overflowY': 'scroll' }} className="pt-5 border-t border-b border-gray-300">
                <ThemeListContainer themes={themes} selectedTheme={configuration.themeNameOrTheme} onThemeSelected={onSelectTheme} />
            </div>
            <div className="flex justify-end">
                <button onClick={() => closeModal()} className="px-5 py-2 bg-indigo-500 hover:bg-indigo-700 text-white cursor-pointer rounded-md">
                    Close</button>
            </div>
        </div>
    </div>
};