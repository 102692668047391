import { useDebouncedEffect } from '@react-hookz/web/esm/useDebouncedEffect';
import { useEffect, useState } from "react";
import FormGroup from "./FormGroup";


const PageTitle = (props) => {
    const { pageTitleTemplate, pageTitleTemplateConfig, refreshPreview } = props;
    
    const [title, setTitle] = useState(pageTitleTemplate);
    useEffect(() => setTitle(pageTitleTemplate), [pageTitleTemplate]); // I don't understand why this is needed to get the default value to update

    useDebouncedEffect(() => refreshPreview('pageTitleTemplate', title), [title], 500);

    return <FormGroup labelKey="pageTitleTemplate" label="Page Title Template">
        <div className='inline-flex items-center'>
            <input id="pageTitleTemplate" type="text" className="w-9/12 border py-2 px-3 text-grey-darkest" value={title} onChange={event => setTitle(event.target.value)} />
            <button onClick={() => setTitle(pageTitleTemplateConfig.defaultTemplate)} type="button" className="inline-flex items-center px-1 mx-2 border border-transparent rounded-full shadow-sm text-white bg-green-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Use Default</button>
        </div>
        <div><span>Default Template:</span><span>{pageTitleTemplateConfig.defaultTemplate}</span></div>
        <div>Valid Variables: {pageTitleTemplateConfig.variables.map(variable => <button key={variable} onClick={() => setTitle(title + variable)} type="button" className="inline-flex items-center px-1 mx-1 border border-transparent rounded-full shadow-sm text-white bg-green-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">{variable}</button>)}</div>
    </FormGroup>
};

export default PageTitle;