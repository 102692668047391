import { Link, Text, View } from '@react-pdf/renderer';
import React from 'react';

const PdfFooter = (props) => {
	const {leftMargin, rightMargin, bottomMargin, fontSize } = props;

	return <View style={{ position: 'absolute', left: leftMargin, right: rightMargin, bottom: bottomMargin, textAlign: 'right', fontSize: fontSize }}>
		<Text>{(new Date()).getFullYear()} Mini Calendar Generator by <Link src={"https://vmac.ch"}>vmac.ch</Link></Text>
	</View>
};

export default PdfFooter;