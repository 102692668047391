import React from 'react';
import WeekPerPageLayout from './WeekPerPageLayout.js';

const WeekPerPageA4 = (props) => {
	const width = props.orientation === 'portrait' ? 595 - 60 : 842 - 60;
	const height = props.orientation === 'portrait' ? 842 - 100 : 595 - 100;

	return <WeekPerPageLayout pageSize='A4' width={width} height={height} marginLeft='1cm' marginRight='1cm' marginBottom='1cm' {...props}/>
};

export default WeekPerPageA4;