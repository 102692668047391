import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import PdfFooter from '../PdfFooter.js';
import WeekCalendar from '../WeekCalendar.js';

// Create styles
const styles = StyleSheet.create({
	page: {
		marginLeft: '0cm',
		marginRight: '0cm'
	},
	section: {
		marginRight: '1cm',
	}
});

const WeekPerPageLayout = (props) => {
	const { pageSize, width, height, weeks, marginLeft, marginRight, marginBottom, prependZero, showWeekends, startWeekOnSundays, orientation, pageTitleTemplateParser, startOfDay, endOfDay } = props;

	const firstDayofWeekDate = (date) => {
		if (showWeekends && startWeekOnSundays) {
			return date.minus({ day: 1 }).toJSDate();
		}

		return date.toJSDate();
	};

	return <Document>
		{weeks.map((weekConfig) =>
			<Page key={'year-' + weekConfig.year + '-' + weekConfig.week} size={pageSize} orientation={orientation} style={styles.page}>
				<View style={{ alignItems: 'center', marginVertical: '0.5cm', fontSize: 20 }}>
					<Text style={{ flexGrow: 1 }}>{pageTitleTemplateParser(weekConfig.year, `${prependZero && weekConfig.week < 10 ? '0' : ''}${weekConfig.week}`)}</Text>
				</View>
				<View style={{ flexDirection: 'row', marginLeft: marginLeft, marginRight: marginRight, marginBottom: marginBottom }}>
					<WeekCalendar width={width} height={height} firstDayofWeekDate={firstDayofWeekDate(weekConfig.date)} numberOfDays={showWeekends ? 7 : 5} startTime={startOfDay} endTime={endOfDay} />
				</View>
				<PdfFooter leftMargin={0} rightMargin={'1cm'} bottomMargin={'5mm'} fontSize={10} />
			</Page>)}
	</Document>
};

export default WeekPerPageLayout;