import { DateTime } from 'luxon';
import { useState } from 'react';
import CalendarThemes from './calendarThemes';
import Customize from './configuration/Customize';
import Footer from './Footer';
import Header from './Header';
import { getPageLayoutFactory } from './pageTypes';
import Preview from './Preview';

function App() {
	const [configuration, setConfiguration] = useState({
		pageTitleTemplate: '$YEAR$',
		years: [{ year: (new Date()).getFullYear().toString(), months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}],
		weeks: [ { year: DateTime.now().startOf('week').toFormat('kkkk'), week: DateTime.now().startOf('week').toFormat('W'), date: DateTime.now().startOf('week') }],
		prependZero: true,
		showWeekends: false,
		startWeekOnSundays: false,
		showWeekColumn: true,
		alignmentHeaderCell:'center',
		alignmentContentCellHorizontal: 'right',
		alignmentContentCellVertical: 'bottom',
		alignmentWeekCell: 'top',
		themeNameOrTheme: 'default',
		orientation: 'portrait',
		layout: 'yearPerPage',
		paperSize: 'A4',
		customMarkings: [],
		startOfDay: 9,
		endOfDay: 17,
	});

	return (
		<div className="h-full w-full flex flex-col bg-white">
			<div className="bg-yellow-600 shadow-lg"><Header /></div>
			<div className="w-full flex-grow flex px-2">
				<div style={{ width: '90%', height: 'calc(100vh - 72px - 41px - 8px)', 'overflowY': 'scroll' }} className="w-2/4 mr-2 flex flex-col">
					<Customize themes={CalendarThemes()} configuration={configuration} update={setConfiguration} />
				</div>
				<div className="w-full flex-grow overflow-hidden">
					<Preview pageFactory={() => getPageLayoutFactory(configuration.layout, configuration.orientation, configuration.paperSize)(configuration)} configuration={configuration} />
				</div>
			</div>
			<div className="bg-gray-50 mt-2"><Footer /></div>
		</div>
	);
}

export default App;
