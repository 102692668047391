import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';

function reviver(key, value)
{
    if (key === 'startDate' || key === 'endDate') return DateTime.fromFormat(value, 'yyyy-MM-dd').toJSDate();

    return value;
}

const ImportDates = (props) => {
	const { closeModal, refreshPreview } = props;

	const [rawData, setRawData] = useState('');
	const [data, setData] = useState(null);
	const [isValid, setIsValid] = useState(false);

	useEffect(() => {
		try {
			let d = JSON.parse(rawData, reviver);
			setData(d);
			setIsValid(true);
		} catch (e) {
			setIsValid(false);
		}
	}, [rawData]);

	const cancelAndClose = () => {
		closeModal();
	};

	const saveAndClose = () => {
		save();
		closeModal();
	};

	const save = useCallback(() => {
		const d = data.reduce((acc, item) => {
			const formatedDate = item.date;
			const group = acc[formatedDate] || [];
			group.push(item);

			acc[formatedDate] = group;
			return acc;
		}, {});

		const a = Object.keys(d).reduce((acc, key) => {
			acc.push({ date: key, cellDecorations: d[key].map((item) => ({ backgroundFillColor: item.colors.background, backgroundStrokeColor: item.colors.border, labelColor: item.colors.text, edgeInset: { left: 1, top: 1, right: 1, bottom: 1 } })) })
			return acc;
		}, []);

		let dates = data.map(item => {
			return { ...item, date: DateTime.fromISO(item.date).toJSDate() };
		})

		refreshPreview(a, dates);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [/*refreshPreview causes cycle!*/ data]);

	const placeholderData = [
		{
			"startDate": "2023-11-08T12:09:30.000Z",
			"endDate": "2023-11-09T12:09:30.000Z",
			"colors": {
				"background": "#8338ec",
				"border": null,
				"text": "#000000"
			}
		}
	];

	return <div className="fixed z-50">
		<div id="overlay" className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"></div>

		<div id="dialog"
			className="fixed z-50  w-1/2 bg-white rounded-md px-8 py-6 space-y-5 drop-shadow-lg">
			<h1 className="text-2xl font-semibold">Import Dates</h1>
			<div style={{ 'minHeight': '200px', 'maxHeight': '70vh', 'overflowY': 'scroll' }} className="py-5 border-t border-b border-gray-300">
				<label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">JSON Data</label>
				<textarea id="message" onChange={event => setRawData(event.target.value)} rows="15" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={JSON.stringify(placeholderData, null, ' ')}></textarea>
				{!isValid && <div className='text-red-500'>JSON is invalid</div>}
			</div>

			<div className="flex justify-end">
				<button onClick={() => cancelAndClose()} className="px-5 mx-3 py-2 bg-indigo-500 hover:bg-indigo-700 text-white cursor-pointer rounded-md">
					Cancel & Back</button>
				{isValid && <button onClick={() => saveAndClose()} disabled={!isValid} className="px-5 py-2 bg-indigo-500 hover:bg-indigo-700 text-white cursor-pointer rounded-md">
					Save & Back</button>}
				{!isValid && <div onClick={() => saveAndClose()} disabled={!isValid} className="px-5 py-2 bg-gray-500 text-white rounded-md">
					Save & Back</div>}
			</div>
		</div>
	</div>
};

export default ImportDates;