import React from 'react';
import WeekPerPageLayout from './WeekPerPageLayout.js';

const WeekPerPageA5 = (props) => {
	const width = props.orientation === 'portrait' ? 419 - 14 - 14 : 595 - 40;
	const height = props.orientation === 'portrait' ? 595 - 100 : 419 - 90;
	
	return <WeekPerPageLayout pageSize='A5' width={width} height={height}  marginLeft='5mm' marginRight='0.0cm' marginBottom='1cm' {...props}/>
};

export default WeekPerPageA5;