import React, { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";

import "react-datepicker/dist/react-datepicker.css";
import "./customDatePickerWidth.css";
import { ColorSelector } from './ColorSelector';

function resolveRanges(dates) {
	const rangesResolved = dates.map(item => {
		if (item.startDate.toDateString() !== item.endDate.toDateString()) {
			console.log()
			let rangeItems = [];
			let currentDate = DateTime.fromJSDate(item.startDate);
			while (currentDate <= item.endDate) {
				rangeItems.push({ ...item, date: currentDate.toJSDate() });
				currentDate = currentDate.plus({ day: 1 });
			}
			return rangeItems;
		}

		return [{ ...item, date: item.startDate }];
	});

	return rangesResolved.flat();
}

const SelectDates = (props) => {
	const { closeModal, importDates, exportDates, refreshPreview, data } = props;

	const colors = ['#ff0000', '#00ff00', '#0000ff', '#ffbe0b', '#fb5607', '#ff006e', '#8338ec', '#3a86ff', '#000000'];

	const [dates, setDates] = useState(data);
	const [scrollToButton, setScrollToButton] = useState(false);

	const save = useCallback(() => {
		const d = resolveRanges(dates).reduce((acc, item) => {
			const formatedDate = DateTime.fromJSDate(item.date).toISODate();
			const group = acc[formatedDate] || [];
			group.push(item);

			acc[formatedDate] = group;
			return acc;
		}, {});

		const customMarkings = Object.keys(d).reduce((acc, key) => {
			acc.push({ date: key, cellDecorations: d[key].map((item) => ({ backgroundFillColor: item.colors.background, backgroundStrokeColor: item.colors.border, labelColor: item.colors.text, edgeInset: { left: 1, top: 1, right: 1, bottom: 1 } })) })
			return acc;
		}, []);

		refreshPreview(customMarkings, dates /* original view state */);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [/*refreshPreview causes cycle!*/ dates]);

	const addButtonWrapperRef = useRef();

	const saveAndClose = () => {
		save();
		closeModal();
	};

	const onImportDates = () => {
		save();
		importDates();
	};

	const onExportDates = () => {
		save();
		exportDates(dates);
	};

	const addDate = useCallback(() => {
		const newDates = [...dates]

		let nextDay = new Date();
		let colors = { background: null, border: null, text: '##000000' };
		if (dates.length > 0) {
			const lastDate = DateTime.fromJSDate(dates[dates.length - 1].endDate);
			nextDay = lastDate.plus({ day: 1 }).toJSDate();
			colors = { ...dates[dates.length - 1].colors };
		}

		newDates.push({ dateValid: true, startDate: nextDay, endDate: nextDay, colors: colors });

		setDates(newDates);
		setScrollToButton(true);
	}, [dates]);

	useEffect(() => {
		if (!scrollToButton) {
			return;
		}

		console.log('Scroll Now');
		setScrollToButton(false);
		addButtonWrapperRef.current.scrollIntoView();
	}, [scrollToButton]);

	const removeDate = useCallback((index) => {
		const newDates = [...dates]
		newDates.splice(index, 1);
		setDates(newDates);
	}, [dates]);

	const updateSelectedColor = useCallback((index, type, color) => {
		const newDates = [...dates]
		newDates[index].colors[type] = color;
		setDates(newDates);
	}, [dates]);

	const updateStartDate = useCallback((index, date) => {
		const newDates = [...dates]

		newDates[index].startDate = date;

		if (date > newDates[index].endDate) {
			newDates[index].endDate = date;
		}

		setDates(newDates);
	}, [dates]);

	const updateEndDate = useCallback((index, date) => {
		const newDates = [...dates]

		if (date >= newDates[index].startDate) {
			newDates[index].endDate = date;
		} else {
			newDates[index].endDate = newDates[index].startDate;
		}

		setDates(newDates);
	}, [dates]);

	useEffect(save, [save, dates]);

	return <div className="fixed z-50">
		<div id="overlay" className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"></div>

		<div id="dialog"
			className="fixed z-50  w-1/2 bg-white rounded-md px-8 py-6 space-y-5 drop-shadow-lg">
			<div className='flex justify-between items-center'>
				<div className='grow-0'><h1 className="text-2xl font-semibold">Highlighted Dates</h1></div>
				<div className='grow'>
					<button onClick={() => onImportDates()} className="px-5 mx-3 py-2 border-2 border-green-600 hover:bg-green-600 text-black cursor-pointer rounded-md">Import</button>
					<button onClick={() => onExportDates()} className="px-5 py-2 border-2 border-green-600 hover:bg-green-600 text-black cursor-pointer rounded-md">Export</button>
				</div>
			</div>
			<div style={{ 'minHeight': '200px', 'maxHeight': '70vh', 'overflowY': 'scroll' }} className="py-5 border-t border-b border-gray-300">
				<table className="table-auto w-full">
					<thead className='border-b-2 border-gray-200'>
						<tr>
							<th className='w-10'>&nbsp;</th>
							<th className="customDatePickerWidth text-left">Start</th>
							<th className="customDatePickerWidth text-left">End</th>
							<th className='w-14'>&nbsp;</th>
							<th className='w-48 text-left'>Background Color</th>
							<th className='w-48 text-left'>Border Color</th>
							<th className='w-48 text-left'>Text Color</th>
						</tr>
					</thead>
					<tbody>
						{dates.map((date, index) => (
							<tr key={`${date.toString()}-${index}`} className={index % 2 === 1 ? 'bg-gray-200' : 'bg-white'}>
								<td className='py-2'>
									<button onClick={removeDate} type="button" className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-green-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" /> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" /> </svg>
									</button>
								</td>

								<td className="customDatePickerWidth">
									<DatePicker selected={date.startDate} onChange={(date) => updateStartDate(index, date)} />
								</td>
								<td className="customDatePickerWidth">
									<DatePicker selected={date.endDate} onChange={(date) => updateEndDate(index, date)} />
								</td>
								<td></td>
								<td className='text-center'><ColorSelector colors={colors} label="" selectedColor={date.colors.background} onSelect={(color) => updateSelectedColor(index, 'background', color)} /></td>
								<td><ColorSelector colors={colors} label="" selectedColor={date.colors.border} onSelect={(color) => updateSelectedColor(index, 'border', color)} /></td>
								<td><ColorSelector colors={colors} label="" selectedColor={date.colors.text} onSelect={(color) => updateSelectedColor(index, 'text', color)} /></td>
							</tr>
						))}
					</tbody>
				</table>

				<div className="justify-self-end mt-3">
					<button onClick={addDate} type="button" className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-green-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
						<svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
							<path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
						</svg>
					</button>
					<div ref={addButtonWrapperRef}></div>
				</div>
			</div>

			<div className="flex justify-end">
				<button onClick={() => saveAndClose()} className="px-5 py-2 bg-indigo-500 hover:bg-indigo-700 text-white cursor-pointer rounded-md">
					Close</button>
			</div>
		</div>
	</div>
};

export default SelectDates;