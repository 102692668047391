import { DateTime } from 'luxon';
import React from 'react';

function replacer(key, value)
{
    if (key === 'dateValid' || key === 'showDatePicker') return undefined;
	if (key === 'startDate' || key === 'endDate') return DateTime.fromISO(value).toFormat('yyyy-MM-dd');

    return value;
}

const ExportDates = (props) => {
	const { closeModal, data } = props;

	const onCopy = () => {
		navigator.clipboard.writeText(JSON.stringify(data, replacer, ' '));
	};

	const saveAndClose = () => {
		closeModal();
	};

	return <div className="fixed z-50">
		<div id="overlay" className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"></div>

		<div id="dialog"
			className="fixed z-50  w-1/2 bg-white rounded-md px-8 py-6 space-y-5 drop-shadow-lg">
			<h1 className="text-2xl font-semibold">Export Dates</h1>
			<div style={{'minHeight': '200px', 'maxHeight': '70vh', 'overflowY': 'scroll'}} className="py-5 border-t border-b border-gray-300">
				<pre>
					{JSON.stringify(data, replacer, ' ')}
				</pre>
			</div>

			<div className="flex justify-between">
			<button onClick={() => onCopy()} className="px-5 mx-3 py-2 border-2 border-green-600 hover:bg-green-600 text-black cursor-pointer rounded-md">Copy</button>
				<button onClick={() => saveAndClose()} className="px-5 py-2 bg-indigo-500 hover:bg-indigo-700 text-white cursor-pointer rounded-md">
					Back</button>
			</div>
		</div>
	</div>
};

export default ExportDates;