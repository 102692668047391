import { useState } from "react";
import { ThemeListContainer } from "./ThemeListContainer";
import { getPageLayout } from "../pageTypes";

export const PageTypeSelector = (props) => {
    const { closeModal, themes, pageLayouts, refreshPreview, configuration, } = props;

    const orientations = ['Portrait', 'Landscape' ];
    const pageSizes = ['A5', 'A4', 'A3', 'Letter' ];
    
    const [orientationIndex, setOrientationIndex] = useState(orientations.findIndex((o) => o.toLowerCase() === configuration.orientation));
    const [pageSizeIndex, setPageSizeIndex] = useState(pageSizes.findIndex((o) => o === configuration.paperSize));
    
    const layouts = Object.keys(pageLayouts).filter(pageLayout => {
        const layout = pageLayouts[pageLayout];
        const selectedOrientation = layout.factories[orientations[orientationIndex].toLowerCase()];
        return selectedOrientation && selectedOrientation[pageSizes[pageSizeIndex]];
    });

    const [layoutIndex, setLayoutIndex] = useState(layouts.findIndex((o) => o === configuration.layout));

    const visibleThemes = Object.fromEntries(Object.entries(themes).filter(([key, theme]) => layoutIndex > -1 && getPageLayout(layouts[layoutIndex]).supportedThemes.includes(key)));
    const hasVisibleThemes = Object.keys(visibleThemes).length > 0;
    const [selectedTheme, setSelectedTheme] = useState(configuration.themeNameOrTheme);
    
    const hasMultipleSupportedOrientations = (layout) => Object.keys(pageLayouts[layout].factories).length > 1;

    const onClickLayout = (index) => {
        setLayoutIndex(index);
        console.log(pageSizes[pageSizeIndex], orientations[orientationIndex].toLowerCase(), layouts[layoutIndex], 'index', index);
        refreshPreview(pageSizes[pageSizeIndex], orientations[orientationIndex].toLowerCase(), layouts[index], selectedTheme ?? 'default');
    };

    const onThemeSelected = (themeKey) => {
        setSelectedTheme(themeKey);
        refreshPreview(pageSizes[pageSizeIndex], orientations[orientationIndex].toLowerCase(), layouts[layoutIndex], themeKey);
    };

    return <div className="fixed z-50">
        <div id="overlay" className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"></div>

        <div id="dialog"
            className="fixed z-50  w-1/2 bg-white rounded-md px-8 py-6 space-y-5 drop-shadow-lg">
            <h1 className="text-2xl font-semibold">Select Page Size and Calendar Layout</h1>
            <div style={{ 'minHeight': '560px', 'maxHeight': '70vh', 'overflowY': 'scroll' }} className="border-t border-b border-gray-300">

                <h2 className="mt-4 text-1xl font-bold dark:text-white">Page Orientation</h2>
                <div className="flex gap-3 my-2">
                    {orientations.map((orientation, i) => 
                        <Card key={orientation} type={orientation} label={orientation} image={`paperSize/${orientation}.png`} alt={orientation} isSelected={orientationIndex === i} onClick={() => { setLayoutIndex(-1); setOrientationIndex(i)}} />
                    )}
                </div>

                <h2 className="mt-8 text-1xl font-bold dark:text-white">Page Type</h2>
                <div className="flex gap-3 my-2">
                    {pageSizes.map((pageSize, i) =>
                        <Card key={pageSize} type={pageSize} label={pageSize} image={`paperSize/${pageSize}.png`} alt={pageSize} rotate={orientationIndex === 1} isSelected={pageSizeIndex === i} onClick={() => { setLayoutIndex(-1); setPageSizeIndex(i)}} />
                    )}
                </div>

                <h2 className="mt-8 text-1xl font-bold dark:text-white">Avaliable Calendar Layouts</h2>
                <div className="flex flex-wrap  my-2">
                    {layouts.map((layout, i) =>
                        <Card key={layout} type={layout} label={pageLayouts[layout].displayName} image={`paperSize/${layout}${hasMultipleSupportedOrientations(layout) ? orientations[orientationIndex].toLocaleLowerCase() : ''}.png`} alt={pageLayouts[layout].displayName ?? layout} isSelected={layoutIndex === i} onClick={() => onClickLayout(i)} />
                    )}
                    {layouts.length === 0 && <div className="text-red-500 italic">No matching calendar layouts found. Try a different combination.</div>}
                </div>

                <h2 className="mt-8 text-1xl font-bold dark:text-white">Choose a Theme</h2>
                <div className="flex flex-wrap  my-2">
                    {hasVisibleThemes && <ThemeListContainer themes={visibleThemes} selectedTheme={selectedTheme} onThemeSelected={onThemeSelected} />}
                    {layoutIndex === -1 && <div className="italic">Select a calendar layout first.</div>}
                    {layoutIndex > 0 && !hasVisibleThemes && <div className="text-red-500 italic">This calendar layout does not have themes.</div>}
                </div>
            </div>
            <div className="flex flex-wrap justify-end my-2">
                <button onClick={() => closeModal()} disabled={layoutIndex < 0} className={'px-5 py-2 rounded-md ' + (layoutIndex < 0 ? 'bg-gray-700 text-gray-400' : 'bg-indigo-500 hover:bg-indigo-700 text-white cursor-pointer')}>
                    Close</button>
            </div>
        </div>
    </div>
};

const Card = (props) => {
    const { type, label, image, alt, rotate = false, isSelected, onClick } = props;

    return <div onClick={() => !isSelected && onClick(type)} style={{ maxWidth: '300px'}} className={'flex flex-col items-center text-center ' + (isSelected ? 'border-4 border-indigo-500 p-1' : 'p-2')}>
        <div className={rotate ? 'transform rotate-90' : ''}>
            <img className='object-contain' src={image} alt={alt} />
        </div>
        <div className="">{label}</div>
    </div>
};