import MonthPerPageA4 from "./MonthPerPageA4";
import SixMonthsPerPageA4 from "./SixMonthsPerPageA4";
import WeekPerPageA4 from "./WeekPerPageA4";
import WeekPerPageA5 from "./WeekPerPageA5";
import YearPerPageA4 from "./YearPerPageA4";
import YearPerPageA5 from "./YearPerPageA5";
import YearPerPageUSLetter from "./YearPerPageUSLetter";

// source: https://www.abeautifulsite.net/posts/getting-localized-month-and-day-names-in-the-browser/
function getDayNames(locale = 'en', format = 'long') {
    const formatter = new Intl.DateTimeFormat(locale, { weekday: format, timeZone: 'UTC' });
    const days = [1, 2, 3, 4, 5, 6, 7].map(day => {
      const dd = day < 10 ? `0${day}` : day;
      return new Date(`2017-01-${dd}T00:00:00+00:00`);
    });
    return days.map(date => formatter.format(date));
}

const pageTitleTemplateParser = (template, year, monthNumber, monthName, week) => {
    let title = template
    
    if (year) {
        title = title.replaceAll('$YEAR$', year);
    } else {
        title = title.replaceAll('$YEAR$', '');
    }

    if (monthNumber) {
        title = title.replaceAll('$MONTH$', monthNumber);
    } else {
        title = title.replaceAll('$MONTH$', '');
    }
    
    if (monthName) {
        title = title.replaceAll('$MONTH_NAME$', monthName);
    } else {
        title = title.replaceAll('$MONTH_NAME$', '');
    }

    if (week) {
        title = title.replaceAll('$WEEK$', week);
    } else {
        title = title.replaceAll('$WEEK$', '');
    }

    return title;
};

const pageTitleTemplateYearOnly = { defaultTemplate: '$YEAR$', variables: ['$YEAR$'] };
const defaultVisibleCustomisationOptions = ['pageType', 'orientation', 'years', 'themeSelector', 'pageTitle', 'leadingZero', 'startOnSundays', 'showWeekColumn', 'markedDates'];

const getPageLayout = (layout) => pageLayouts[layout];

const getPageLayoutFactory = (layout, orientation, paperSize) => getPageLayout(layout).factories[orientation.toLowerCase()][paperSize];

const miniCalendarThemes = ["default","defaultGreen","defaultBlue","noGridYellow","noGridGreen","noGridBlue","noWeekendHighlight","noWeekendHighlightNoGrid","modern","modern2","modern3","modern4","modern5"];

const pageLayouts = {
    yearPerPage: {
        displayName: 'One year per page',
        pageTitleTemplateConfig: pageTitleTemplateYearOnly,
        visibleCustomisationOptions: defaultVisibleCustomisationOptions,
        supportedThemes: miniCalendarThemes,
        factories: {
            'portrait': {
                'A5': (c) => <YearPerPageA5 pageTitleTemplateParser={(year) => pageTitleTemplateParser(c.pageTitleTemplate, year)} years={c.years} prependZero={c.prependZero} startWeekOnSundays={c.startWeekOnSundays} showWeekColumn={c.showWeekColumn} themeName={c.themeNameOrTheme} orientation={c.orientation} customMarkings={c.customMarkings} />,
                'A4': (c) => <YearPerPageA4 pageTitleTemplateParser={(year) => pageTitleTemplateParser(c.pageTitleTemplate, year)} years={c.years} prependZero={c.prependZero} startWeekOnSundays={c.startWeekOnSundays} showWeekColumn={c.showWeekColumn} themeName={c.themeNameOrTheme} orientation={c.orientation} customMarkings={c.customMarkings} />,
                'Letter': (c) => <YearPerPageUSLetter pageTitleTemplateParser={(year) => pageTitleTemplateParser(c.pageTitleTemplate, year)} years={c.years} prependZero={c.prependZero} startWeekOnSundays={c.startWeekOnSundays} showWeekColumn={c.showWeekColumn} themeName={c.themeNameOrTheme} orientation={c.orientation} customMarkings={c.customMarkings} />,
            },
            'landscape': {
                'A5': (c) => <YearPerPageA5 pageTitleTemplateParser={(year) => pageTitleTemplateParser(c.pageTitleTemplate, year)} years={c.years} prependZero={c.prependZero} startWeekOnSundays={c.startWeekOnSundays} showWeekColumn={c.showWeekColumn} themeName={c.themeNameOrTheme} orientation={c.orientation} customMarkings={c.customMarkings} />,
                'A4': (c) => <YearPerPageA4 pageTitleTemplateParser={(year) => pageTitleTemplateParser(c.pageTitleTemplate, year)} years={c.years} prependZero={c.prependZero} startWeekOnSundays={c.startWeekOnSundays} showWeekColumn={c.showWeekColumn} themeName={c.themeNameOrTheme} orientation={c.orientation} customMarkings={c.customMarkings} />,
                'Letter': (c) => <YearPerPageUSLetter pageTitleTemplateParser={(year) => pageTitleTemplateParser(c.pageTitleTemplate, year)} years={c.years} prependZero={c.prependZero} startWeekOnSundays={c.startWeekOnSundays} showWeekColumn={c.showWeekColumn} themeName={c.themeNameOrTheme} orientation={c.orientation} customMarkings={c.customMarkings} />,
            }
        }
    },
    monthPerPage: {
        displayName: 'One month per page',
        pageTitleTemplateConfig: { defaultTemplate: '$YEAR$ $MONTH_NAME$', variables: ['$YEAR$', '$MONTH$', '$MONTH_NAME$'] },
        visibleCustomisationOptions: [...defaultVisibleCustomisationOptions, 'alignmentContentCellHorizontal'],
        supportedThemes: miniCalendarThemes,
        factories: {
            'landscape': {
                'A4': (c) => <MonthPerPageA4 pageTitleTemplateParser={(year, month, monthName) => pageTitleTemplateParser(c.pageTitleTemplate, year, month, monthName)} years={c.years} prependZero={c.prependZero} startWeekOnSundays={c.startWeekOnSundays} showWeekColumn={c.showWeekColumn} themeName={c.themeNameOrTheme} orientation={c.orientation} customMarkings={c.customMarkings} weekDays={getDayNames(navigator.language || navigator.userLanguage, 'long')} alignmentContentCellHorizontal={c.alignmentContentCellHorizontal} alignmentContentCellVertical={c.alignmentContentCellVertical} alignmentHeaderCell={c.alignmentHeaderCell} alignmentWeekCell={c.alignmentWeekCell} />,
            }
        }
    },
    sixMonthsPerPage: {
        displayName: 'Portrait six months per page',
        pageTitleTemplateConfig: pageTitleTemplateYearOnly,
        visibleCustomisationOptions: defaultVisibleCustomisationOptions,
        supportedThemes: miniCalendarThemes,
        factories: {
            'portrait': {
                'A4': (c) => <SixMonthsPerPageA4 pageTitleTemplateParser={(year) => pageTitleTemplateParser(c.pageTitleTemplate, year)} years={c.years} prependZero={c.prependZero} startWeekOnSundays={c.startWeekOnSundays} showWeekColumn={c.showWeekColumn} themeName={c.themeNameOrTheme} orientation={'portrait'} customMarkings={c.customMarkings} />,
            },
        }
    },
    twoColumnSixMonthsPerPageMonthsHorizontal: {
        displayName: 'Two column six months per page (months flow horizontal)',
        pageTitleTemplateConfig: pageTitleTemplateYearOnly,
        visibleCustomisationOptions: defaultVisibleCustomisationOptions,
        supportedThemes: miniCalendarThemes,
        factories: {
            'landscape': {
                'A4': (c) => <SixMonthsPerPageA4 pageTitleTemplateParser={(year) => pageTitleTemplateParser(c.pageTitleTemplate, year)} years={c.years} prependZero={c.prependZero} startWeekOnSundays={c.startWeekOnSundays} showWeekColumn={c.showWeekColumn} themeName={c.themeNameOrTheme} orientation={'landscape'} monthsVertical={false} customMarkings={c.customMarkings} />,
            }
        }
    },
    twoColumnSixMonthsPerPageMonthsVertical: {
        displayName: 'Two column six months per page (months flow vertical)',
        pageTitleTemplateConfig: pageTitleTemplateYearOnly,
        visibleCustomisationOptions: defaultVisibleCustomisationOptions,
        supportedThemes: miniCalendarThemes,
        factories: {
            'landscape': {
                'A4': (c) => <SixMonthsPerPageA4 pageTitleTemplateParser={(year) => pageTitleTemplateParser(c.pageTitleTemplate, year)} years={c.years} prependZero={c.prependZero} startWeekOnSundays={c.startWeekOnSundays} showWeekColumn={c.showWeekColumn} themeName={c.themeNameOrTheme} orientation={'landscape'} monthsVertical={true} customMarkings={c.customMarkings} />,
            }
        }
    },
    weekPerPage: {
        displayName: 'Week Overview',
        pageTitleTemplateConfig: { defaultTemplate: '$YEAR$ Week $WEEK$', variables: ['$YEAR$', '$WEEK$'] },
        visibleCustomisationOptions: ['pageType', 'orientation', 'weeks', 'pageTitle', 'showWeekends', 'startOnSundays', 'startOfDay'],
        supportedThemes: [],
        factories: {
            'portrait': {
                'A5': (c) => <WeekPerPageA5 pageTitleTemplateParser={(year, week) => pageTitleTemplateParser(c.pageTitleTemplate, year, undefined, undefined, week)} weeks={c.weeks} orientation={c.orientation} prependZero={c.prependZero} showWeekends={c.showWeekends} startWeekOnSundays={c.startWeekOnSundays} showWeekColumn={c.showWeekColumn} startOfDay={c.startOfDay} endOfDay={c.endOfDay} />,
                'A4': (c) => <WeekPerPageA4 pageTitleTemplateParser={(year, week) => pageTitleTemplateParser(c.pageTitleTemplate, year, undefined, undefined, week)} weeks={c.weeks} orientation={c.orientation} prependZero={c.prependZero} showWeekends={c.showWeekends} startWeekOnSundays={c.startWeekOnSundays} showWeekColumn={c.showWeekColumn} startOfDay={c.startOfDay} endOfDay={c.endOfDay} />,
            },
            'landscape': {
                'A5': (c) => <WeekPerPageA5 pageTitleTemplateParser={(year, week) => pageTitleTemplateParser(c.pageTitleTemplate, year, undefined, undefined, week)} weeks={c.weeks} orientation={c.orientation} prependZero={c.prependZero} showWeekends={c.showWeekends} startWeekOnSundays={c.startWeekOnSundays} showWeekColumn={c.showWeekColumn} startOfDay={c.startOfDay} endOfDay={c.endOfDay} />,
                'A4': (c) => <WeekPerPageA4 pageTitleTemplateParser={(year, week) => pageTitleTemplateParser(c.pageTitleTemplate, year, undefined, undefined, week)} weeks={c.weeks} orientation={c.orientation} prependZero={c.prependZero} showWeekends={c.showWeekends} startWeekOnSundays={c.startWeekOnSundays} showWeekColumn={c.showWeekColumn} startOfDay={c.startOfDay} endOfDay={c.endOfDay} />,
            }
        }
    }
};

export {getPageLayout, getPageLayoutFactory, pageLayouts, YearPerPageA4, YearPerPageA5};