import { useDebouncedEffect } from '@react-hookz/web/esm/useDebouncedEffect';
import { useState } from "react";
import FormGroup from "./FormGroup";

const TimeDuration = (props) => {
    const { startHour, endHour, refreshPreview } = props;

    const [startOfDay, setStartOfDay] = useState(startHour);
    const [endOfDay, setEndOfDay] = useState(endHour);

    const isValidAndSmaller = (value, smaller) => {
        try {
            const a = parseInt(value, 10);
            return !isNaN(a) && a >= 0 && a < smaller && a <= 24;
        } catch {
        }
        
        return false;
    }
    const isValidAndBigger = (value, bigger) => {
        try {
            const a = parseInt(value, 10);
            return !isNaN(a) && a >= 0 && a > bigger && a <= 24;
        } catch {
        }
        
        return false;
    }

    useDebouncedEffect(() => {
        if (isValidAndSmaller(startOfDay, endOfDay) && isValidAndBigger(endOfDay, startOfDay)) {
            refreshPreview(parseInt(startOfDay, 10), parseInt(endOfDay, 10))
        }
    }, [startOfDay, endOfDay], 500);

    return <div className="flex flex-row items-middle grow">
        <FormGroup labelKey="startOfDay" label="Start Of Day">
            <input id="startOfDay" type="text" className="w-9/12 border py-2 px-3 text-grey-darkest" value={startOfDay} onChange={event => setStartOfDay(event.target.value)} />
        </FormGroup>
        <FormGroup labelKey="endOfDay" label="End Of Day">
            <input id="endOfDay" type="text" className="w-9/12 border py-2 px-3 text-grey-darkest" value={endOfDay} onChange={event => setEndOfDay(event.target.value)} />
        </FormGroup>
    </div>
};

export default TimeDuration;
