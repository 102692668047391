import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import MiniCalendar from '../MiniCalendar.js';
import PdfFooter from '../PdfFooter.js';

// Create styles
const styles = StyleSheet.create({
	page: {
		marginLeft: '0cm',
		marginRight: '0cm'
	},
	section: {
		marginRight: '1cm',
	}
});

const cellDecorationsForMonth = (customMarkings, year, month) => {
	console.log(month);
	const datePrefix = `${year}-${month <= 9 ? '0' : ''}${month}-`;
	return Object.fromEntries(
		customMarkings
			.filter((cm) => cm.date.startsWith(datePrefix))
			.map(cm => [parseInt(cm.date.substring(8), 10), cm.cellDecorations])
	);
}

const PageContent = (props) => {
	const { yearConfig, monthOffset, prependZero, startWeekOnSundays, showWeekColumn, themeName, customMarkings } = props;

	return <View style={{ flexDirection: 'column', marginLeft: '2cm', marginRight: '2cm' }}>
		{Array(6).fill(0).map((_, i) =>
		((monthOffset + i) < yearConfig.months.length &&
			<View key={'test-' + i}>
				<View style={{ flexDirection: 'row' }}>
					<MiniCalendar width={143} height={115} year={yearConfig.year} month={yearConfig.months[monthOffset + i]} prependZero={prependZero} startOfWeekDay={startWeekOnSundays ? 0 : 1} isoWeekColumnMode={showWeekColumn ? 'fixed' : 'none'} themeName={themeName} fontSize={10} cellDecorations={cellDecorationsForMonth(customMarkings, yearConfig.year, yearConfig.months[monthOffset + i])}/>
					<View key={'test-' + i} style={{ width: '11.5cm', marginTop: '3mm', marginLeft: '5mm' }}>
						{Array(7).fill(0).map((_, i) => <View key={'test-' + i} style={{ width: '11.5cm', marginTop: '5mm', borderBottom: '1px solid black' }} />)}
					</View>
				</View>
				<View style={{ marginBottom: '5mm' }} />
			</View>
		)
		)}
	</View>
}

const PageContentLandscapeMonthsVertical = (props) => {
	const { yearConfig, monthOffset, prependZero, startWeekOnSundays, showWeekColumn, themeName, customMarkings } = props;

	return <View style={{ flexDirection: 'row', marginLeft: '2cm', marginRight: '2cm' }}>
		{Array(2).fill(0).map((_, col) =>
			<View key={'col-' + col} style={{ flexDirection: 'column', marginLeft: col > 0 ? '1cm' : '0cm' }}>
				{Array(3).fill(0).map((_, i) =>
				((monthOffset + (col * 3) + i) < yearConfig.months.length &&
					<View key={'row-' + i}>
						<View style={{ flexDirection: 'row', marginBottom: '1cm' }}>
							<MiniCalendar width={156} height={125} year={yearConfig.year} month={yearConfig.months[monthOffset + (col * 3) + i]} prependZero={prependZero} startOfWeekDay={startWeekOnSundays ? 0 : 1} isoWeekColumnMode={showWeekColumn ? 'fixed' : 'none'} themeName={themeName} fontSize={10} cellDecorations={cellDecorationsForMonth(customMarkings, yearConfig.year, yearConfig.months[monthOffset + (col * 3) + i])}/>
							<View key={'test-' + i} style={{ width: '6.4cm', marginTop: '0mm', marginLeft: '5mm' }}>
								{Array(7).fill(0).map((_, i) => <View key={'test-' + i} style={{ width: '6.4cm', marginTop: '6mm', borderBottom: '1px solid black' }} />)}
							</View>
						</View>

					</View>
				)
				)}
			</View>)}
	</View>
}

const PageContentLandscapeMonthsHorizontal = (props) => {
	const { yearConfig, monthOffset, prependZero, startWeekOnSundays, showWeekColumn, themeName, customMarkings } = props;

	return <View style={{ flexDirection: 'row', marginLeft: '2cm', marginRight: '2cm' }}>
		{Array(2).fill(0).map((_, col) =>
			<View key={'col-' + col} style={{ flexDirection: 'column', marginLeft: col > 0 ? '1cm' : '0cm' }}>
				{Array(3).fill(0).map((_, i) =>
				((monthOffset + col + i * 2) < yearConfig.months.length &&
					<View key={'row-' + i}>
						<View style={{ flexDirection: 'row', marginBottom: '1cm' }}>
							<MiniCalendar width={156} height={125} year={yearConfig.year} month={yearConfig.months[monthOffset + col + i * 2]} prependZero={prependZero} startOfWeekDay={startWeekOnSundays ? 0 : 1} isoWeekColumnMode={showWeekColumn ? 'fixed' : 'none'} themeName={themeName} fontSize={10} cellDecorations={cellDecorationsForMonth(customMarkings, yearConfig.year, yearConfig.months[monthOffset + col + i * 2])}/>
							<View style={{ width: '6.4cm', marginTop: '0mm', marginLeft: '5mm' }}>
								{Array(7).fill(0).map((_, i) => <View key={'test-' + i} style={{ width: '6.4cm', marginTop: '6mm', borderBottom: '1px solid black' }} />)}
							</View>
						</View>
					</View>
				)
				)}
			</View>)}
	</View>
}

const SixMonthsPerPagePortait = (props) => {
	const { years, prependZero, startWeekOnSundays, showWeekColumn, themeName, customMarkings, pageTitleTemplateParser } = props;

	return <Document>
		{years.map((yearConfig) => <React.Fragment  key={'year-' + yearConfig.year}>
			<Page size="A4" orientation="portrait" style={styles.page}>
				<View style={{ alignItems: 'center', marginVertical: '5mm', fontSize: 20 }}>
					<Text style={{ flexGrow: 1 }}>{pageTitleTemplateParser(yearConfig.year)}</Text>
				</View>
				<PageContent yearConfig={yearConfig} monthOffset={0} prependZero={prependZero} startWeekOnSundays={startWeekOnSundays} showWeekColumn={showWeekColumn} themeName={themeName} customMarkings={customMarkings} />
				<PdfFooter leftMargin={0} rightMargin={'2cm'} bottomMargin={'5mm'} fontSize={10} />
			</Page>
			{yearConfig.months.length > 6 && (
				<Page size="A4" orientation="portrait" style={styles.page}>
					<View style={{ alignItems: 'center', marginVertical: '5mm', fontSize: 20 }}>
						<Text style={{ flexGrow: 1 }}>{pageTitleTemplateParser(yearConfig.year)}</Text>
					</View>
					<PageContent yearConfig={yearConfig} monthOffset={6} prependZero={prependZero} startWeekOnSundays={startWeekOnSundays} showWeekColumn={showWeekColumn} themeName={themeName} customMarkings={customMarkings} />
					<PdfFooter leftMargin={0} rightMargin={'2cm'} bottomMargin={'5mm'} fontSize={10} />
				</Page>
			)}
		</React.Fragment>)}
	</Document>
};

const SixMonthsPerPageLandscape = (props) => {
	const { years, monthsVertical, prependZero, startWeekOnSundays, showWeekColumn, themeName, customMarkings, pageTitleTemplateParser } = props;

	return <Document>
		{years.map((yearConfig) => <React.Fragment key={'year-' + yearConfig.year}>
			<Page  size="A4" orientation="landscape" style={styles.page}>
				<View style={{ alignItems: 'center', marginVertical: '1cm', fontSize: 20 }}>
					<Text style={{ flexGrow: 1 }}>{pageTitleTemplateParser(yearConfig.year)}</Text>
				</View>
				{!monthsVertical && <PageContentLandscapeMonthsHorizontal yearConfig={yearConfig} monthOffset={0} prependZero={prependZero} startWeekOnSundays={startWeekOnSundays} showWeekColumn={showWeekColumn} themeName={themeName} customMarkings={customMarkings} />}
				{monthsVertical && <PageContentLandscapeMonthsVertical yearConfig={yearConfig} monthOffset={0} prependZero={prependZero} startWeekOnSundays={startWeekOnSundays} showWeekColumn={showWeekColumn} themeName={themeName} customMarkings={customMarkings} />}
				<PdfFooter leftMargin={0} rightMargin={'2cm'} bottomMargin={'5mm'} fontSize={10} />
			</Page>
			{yearConfig.months.length > 6 && (
				<Page size="A4" orientation="landscape" style={styles.page}>
					<View style={{ alignItems: 'center', marginVertical: '1cm', fontSize: 20 }}>
						<Text style={{ flexGrow: 1 }}>{yearConfig.year}</Text>
					</View>
					{!monthsVertical && <PageContentLandscapeMonthsHorizontal yearConfig={yearConfig} monthOffset={6} prependZero={prependZero} startWeekOnSundays={startWeekOnSundays} showWeekColumn={showWeekColumn} themeName={themeName} customMarkings={customMarkings} />}
					{monthsVertical && <PageContentLandscapeMonthsVertical yearConfig={yearConfig} monthOffset={6} prependZero={prependZero} startWeekOnSundays={startWeekOnSundays} showWeekColumn={showWeekColumn} themeName={themeName} customMarkings={customMarkings} />}
					<PdfFooter leftMargin={0} rightMargin={'2cm'} bottomMargin={'5mm'} fontSize={10} />
				</Page>
			)}
		</React.Fragment>)}
	</Document>
};

const SixMonthsPerPageA4 = (props) => {
	const { years, prependZero, startWeekOnSundays, showWeekColumn, themeName, orientation, monthsVertical, customMarkings, pageTitleTemplateParser } = props;

	return orientation === 'portrait' ?
		<SixMonthsPerPagePortait years={years} prependZero={prependZero} startWeekOnSundays={startWeekOnSundays} showWeekColumn={showWeekColumn} themeName={themeName} customMarkings={customMarkings} pageTitleTemplateParser={pageTitleTemplateParser} /> :
		<SixMonthsPerPageLandscape years={years} monthsVertical={monthsVertical ?? false} prependZero={prependZero} startWeekOnSundays={startWeekOnSundays} showWeekColumn={showWeekColumn} themeName={themeName} customMarkings={customMarkings} pageTitleTemplateParser={pageTitleTemplateParser} />;
}

export default SixMonthsPerPageA4;