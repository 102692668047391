import React, { useCallback, useRef, useState } from 'react';
import Block from '@uiw/react-color-block';
import { useClickOutside } from '@react-hookz/web/esm/useClickOutside';
import { useKeyboardEvent } from '@react-hookz/web/esm/useKeyboardEvent';


export const ColorSelector = (props) => {
    const { label, colors, selectedColor, onSelect } = props;

    const [showPicker, setShowPicker] = useState(false);
    const [color, setColor] = useState(selectedColor);

    const onChange = useCallback((color) => {
        setColor(color.hex);
        onSelect(color.hex);
        setShowPicker(false);
    }, [onSelect]);

    const onClear = useCallback(() => {
        setColor(null);
        onSelect(null);
    }, [onSelect]);

    const ref = useRef(null);

    useClickOutside(ref, () => {
        setShowPicker(false);
    });

    useKeyboardEvent(true, (ev) => {
        if (ev.key === 'Escape') {
            setShowPicker(false);
        }
    }, [], { eventOptions: { passive: true } });

    return <div className="flex flex-row grow items-center">
        <div>{label}</div>
        <div className="relative">
            <div className="border border-black mx-1.5">
                <div className="m-1" style={{ width: '15px', height: '15px', backgroundColor: color }} onClick={() => setShowPicker(true)}></div>
            </div>
            
            <div ref={ref} className='absolute top-full my-3' style={{ left: '-61px', zIndex: 1000 }}>{showPicker && <Block color={color} colors={colors} onChange={onChange} />}</div>
        </div>
        <div className="ml-1 mr-5">
                <button onClick={onClear} type="button" className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-green-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" /> <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" /> </svg>
                </button>
            </div>
    </div>
}