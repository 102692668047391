import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { CustomizeTheme } from '../modals/CustomizeTheme';
import SelectDates from '../modals/SelectDates';
import FormGroup from './FormGroup';
import PageTitle from './PageTitle';
import TimeDuration from './TimeDuration.js';
import WeekSelector from './WeekSelector.js';
import ExportDates from '../modals/ExportDates';
import ImportDates from '../modals/ImportDates';
import { ThemeSelector } from '../modals/ThemeSelector';
import { PageTypeSelector } from '../modals/PageTypeSelector';
import { getPageLayout, pageLayouts } from '../pageTypes';



const Customize = (props) => {
	const { themes, configuration, update } = props;

	const [themeName, setThemeName] = useState(configuration.themeNameOrTheme ?? 'default');
	const [customTheme, setCustomTheme] = useState('');

	const [years, setYears] = useState([
		{ year: (new Date()).getFullYear().toString(), months: Array(12).fill(true) }
	]);

	const [showPageTypeSelectorModal, setPageTypeSelectorModal] = useState(false);
	const [showThemeSelectorModal, setShowThemeSelectorModal] = useState(false);
	const [showCustomizeThemeModal, setShowCustomizeThemeModal] = useState(false);
	const [showSelectDatesModal, setShowSelectDatesModal] = useState(false);
	const [showImportDatesModal, setShowImportDatesModal] = useState(false);
	const [showExportDatesModal, setShowExportDatesModal] = useState(false);
	const [customMarkingsData, setCustomMarkingsData] = useState([
		{ showDatePicker: false, dateValid: false, startDate: DateTime.now().toJSDate(), endDate: DateTime.now().toJSDate(), colors: { background: null, border: null, text: '#000000' } }
	]);

	useEffect(() => {
		setCustomTheme(JSON.stringify(themes[themeName], null, '  '));
		update(updateProperty('themeNameOrTheme', themeName));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [themeName]
	);

	const updateCustomMarkings = (customMarkings, orginalState) => {
		setCustomMarkingsData(orginalState);
		update(updateProperty('customMarkings', customMarkings));
	};

	const updateCalendarSelection = (paperSize, orientation, layout, theme) => {
		const pageLayout = getPageLayout(layout)
		setThemeName(theme);
		update(updateProperty('paperSize', paperSize, 'orientation', orientation, 'layout', layout,  'pageTitleTemplate', pageLayout.pageTitleTemplateConfig.defaultTemplate, 'themeNameOrTheme', theme))
	};

	const updateWithCustomTheme = (theme) => {
		setCustomTheme(JSON.stringify(theme, null, '  '));
		update(updateProperty('themeNameOrTheme', theme));
	};

	const updateProperty = (key, value, key2, value2, key3, value3, key4, value4, key5, value5) => {
		let copy = { ...configuration, }
		copy[key] = value;
		if (key2 && value2) {
			copy[key2] = value2;
		}

		if (key3 && value3) {
			console.log(key3, value3);
			copy[key3] = value3;
		}

		if (key4 && value4) {
			console.log(key4, value4);
			copy[key4] = value4;
		}

		if (key5 && value5) {
			console.log(key5, value5);
			copy[key5] = value5;
		}

		console.info(copy);
		return copy;
	}

	const labelFor = (k, label) => <label htmlFor={k} className="mb-2 uppercase font-bold text-lg text-grey-darkest">{label}</label>;

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			const d = years.map(year => { return { year: year.year, months: year.months.flatMap((selected, i) => selected ? [i + 1] : []) } });
			update(updateProperty('years', d));
		}, 500);
		return () => clearTimeout(timeOutId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [years]);

	const updateYear = (yearIndex, value) => {
		const data = [...years];
		data[yearIndex].year = value;
		setYears(data);
	}

	const updateMonthSelection = (yearIndex, monthIndex) => {
		const data = [...years];
		data[yearIndex].months[monthIndex] = !data[yearIndex].months[monthIndex];
		setYears(data);
	}

	const removeYear = (yearIndex) => {
		const data = [...years];
		data.splice(yearIndex, 1);
		setYears(data);
	}

	const addYear = () => {
		const data = [...years, { year: parseInt(years[years.length - 1].year, 10) + 1, months: Array(12).fill(true) }];
		setYears(data);
	}

	const optionVisible = (option) => {
		const config = getPageLayout(configuration.layout);
		return !config.visibleCustomisationOptions || config.visibleCustomisationOptions.includes(option);
	}

	const orientationValue = () => {
		const config = getPageLayout(configuration.layout);
		const suportedOrientations = Object.keys(config.factories);
		console.log('suportedOrientations', suportedOrientations);
		if (suportedOrientations.includes(configuration['orientation'])) {
			return configuration['orientation'];
		}

		configuration['orientation'] = suportedOrientations[0];
		return configuration['orientation'];
	}

	const canChangeOrientations = () => {
		const config = getPageLayout(configuration.layout);
		const suportedOrientations = Object.keys(config.factories);
		return suportedOrientations.length > 1;
	}

	return <div className="flex flex-col flex-grow pt-2">
		<FormGroup>
			<div className="flex gap-4 items-center p-2">
				<div>{configuration.paperSize} {getPageLayout(configuration.layout).displayName}</div>
				<button onClick={() => setPageTypeSelectorModal(true)} type="button" className="inline-flex items-center p-1 px-6 border border-transparent rounded-full shadow-sm text-white bg-green-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Select Page Type and Calendar Layout ...</button>
			</div>
		</FormGroup>
		{optionVisible('orientation') && <FormGroup>
			{labelFor('orientation', 'Page Orientation')}
			<select id="orientation" value={orientationValue()} disabled={!canChangeOrientations()} onChange={event => update(updateProperty('orientation', event.target.value))}>
				<option key={'portrait'} value={'portrait'}>Portrait</option>
				<option key={'landscape'} value={'landscape'}>Landscape</option>
			</select>
		</FormGroup>}
		{optionVisible('years') && <FormGroup>
			{labelFor('years', 'Years')}
			<div className="overflow-y-auto max-h-56 shadow-inner p-2">
				{years.map((year, yi) => (
					<div key={yi} className="flex flex-row items-middle grow">

						<input type="text" style={{ width: '70px' }} className="border py-2 px-3 text-grey-darkest" value={year.year} onChange={event => updateYear(yi, event.target.value)} />
						<div className="flex flex-row p-2">
							{year.months.map((enabled, month) => (
								<div key={month} onClick={() => updateMonthSelection(yi, month)} className="mx-1">
									{enabled && <div className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">{(new Date(year.year, month)).toLocaleString('default', { month: 'short' })}</div>}
									{!enabled && <div className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">{(new Date(year.year, month)).toLocaleString('default', { month: 'short' })}</div>}
								</div>
							))}
						</div>
						<div className="w-1/3 p-2 flex justify-items-stretch">
							{years.length > 1 && <div className="justify-self-end">
								<button onClick={() => removeYear(yi)} type="button" className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
									<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
										<path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
									</svg>
								</button>
							</div>}
							{years.length === 1 && <div style={{ width: '30px' }}></div>}
						</div>
					</div>
				))}
			</div>
			<div className="w-1/3 flex justify-items-stretch my-4 mx-1">
				<div className="justify-self-end">
					<button onClick={addYear} type="button" className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-green-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
						<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
							<path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
						</svg>
					</button>
				</div>
			</div>
		</FormGroup>}
		{optionVisible('weeks') && <WeekSelector defaultWeeks={configuration.weeks} refreshPreview={(weeks) => update(updateProperty('weeks', weeks))} />}
		{optionVisible('themeSelector') && <FormGroup>
			{labelFor('themeSelector', 'Theme')}
			<div className="flex flex-row p-2">
				<div className="flex flex-col gap-1 w-40">
                        <div className='p-1'>
                            <div >
                                <img className='object-contain' src={`calendarThemesThumbnails/${themeName}.png`} alt={themes[themeName].displayName ?? themeName} />
                            </div>
                            <div className="mt-1">{themes[themeName].displayName ?? themeName}</div>
                        </div>
                    </div>
				<div className='flex flex-col justify-center ml-4'>
					<div className="p-2">
						<button onClick={() => setShowThemeSelectorModal(true)} type="button" className="inline-flex items-center p-1 px-6 border border-transparent rounded-full shadow-sm text-white bg-green-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
						Select Theme ...
					</button></div>
					<div className="p-2"><button onClick={() => setShowCustomizeThemeModal(true)} type="button" className="px-2  border-2 border-green-600 hover:bg-green-600 text-black cursor-pointer rounded-md">
						Customize Theme ...
					</button></div>
				</div>
			</div>
		</FormGroup>}
		{optionVisible('pageTitle') && <PageTitle pageTitleTemplate={configuration.pageTitleTemplate} pageTitleTemplateConfig={getPageLayout(configuration.layout).pageTitleTemplateConfig} refreshPreview={(key, value) => update(updateProperty('pageTitleTemplate', value))} />}
		{optionVisible('leadingZero') && <FormGroup>
			{labelFor('leadingZero', 'Add leading zero to Numbers')}
			<input id="leadingZero" type="checkbox" defaultChecked={configuration['prependZero']} onChange={event => update(updateProperty('prependZero', event.target.checked))} />
		</FormGroup>}
		{optionVisible('showWeekends') && <FormGroup>
			{labelFor('showWeekends', 'Show Weekends')}
			<input id="showWeekends" type="checkbox" defaultChecked={configuration['showWeekends']} onChange={event => update(updateProperty('showWeekends', event.target.checked))} />
		</FormGroup>}
		{optionVisible('startOnSundays') && <FormGroup>
			{labelFor('startOnSundays', 'Start Weeks on Sundays')}
			<input id="startOnSundays" type="checkbox" defaultChecked={configuration['startWeekOnSundays']} onChange={event => update(updateProperty('startWeekOnSundays', event.target.checked))} />
		</FormGroup>}
		{optionVisible('showWeekColumn') && <FormGroup>
			{labelFor('showWeekColumn', 'Show ISO week column')}
			<input id="showWeekColumn" type="checkbox" defaultChecked={configuration['showWeekColumn']} onChange={event => update(updateProperty('showWeekColumn', event.target.checked))} />
		</FormGroup>}
		{optionVisible('alignmentContentCellHorizontal') && <FormGroup>
			<div className="mb-2 uppercase font-bold text-lg text-grey-darkest">Content Alignment</div>
			<div className='flex gap-x-4'>
				<span className='w-20'>Header</span>
				<label><input className='mr-1' id="alignmentHeaderCell0" type="radio" name='alignmentHeaderCell' defaultChecked={configuration['alignmentHeaderCell'] === 'left'} onChange={event => update(updateProperty('alignmentHeaderCell', 'left'))} />Left</label>
				<label><input className='mr-1' id="alignmentHeaderCell1" type="radio" name='alignmentHeaderCell' defaultChecked={configuration['alignmentHeaderCell'] === 'center'} onChange={event => update(updateProperty('alignmentHeaderCell', 'center'))} />Center</label>
				<label><input className='mr-1' id="alignmentHeaderCell2" type="radio" name='alignmentHeaderCell' defaultChecked={configuration['alignmentHeaderCell'] === 'right'} onChange={event => update(updateProperty('alignmentHeaderCell', 'right'))} />Right</label>
			</div>
			<div className='flex gap-x-4'>
				<span className='w-20'>Content</span>
				<label><input className='mr-1' id="alignmentContentCellHorizontal0" type="radio" name='alignmentContentCellHorizontal' defaultChecked={configuration['alignmentContentCellHorizontal'] === 'left'} onChange={event => update(updateProperty('alignmentContentCellHorizontal', 'left'))} />Left</label>
				<label><input className='mr-1' id="alignmentContentCellHorizontal1" type="radio" name='alignmentContentCellHorizontal' defaultChecked={configuration['alignmentContentCellHorizontal'] === 'center'} onChange={event => update(updateProperty('alignmentContentCellHorizontal', 'center'))} />Center</label>
				<label><input className='mr-1' id="alignmentContentCellHorizontal2" type="radio" name='alignmentContentCellHorizontal' defaultChecked={configuration['alignmentContentCellHorizontal'] === 'right'} onChange={event => update(updateProperty('alignmentContentCellHorizontal', 'right'))} />Right</label>
				<span>/</span>
				<label><input className='mr-1' id="alignmentContentCellVertical0" type="radio" name='alignmentContentCellVertical' defaultChecked={configuration['alignmentContentCellVertical'] === 'top'} onChange={event => update(updateProperty('alignmentContentCellVertical', 'top'))} />Top</label>
				<label><input className='mr-1' id="alignmentContentCellVertical1" type="radio" name='alignmentContentCellVertical' defaultChecked={configuration['alignmentContentCellVertical'] === 'middle'} onChange={event => update(updateProperty('alignmentContentCellVertical', 'middle'))} />Middle</label>
				<label><input className='mr-1' id="alignmentContentCellVertical2" type="radio" name='alignmentContentCellVertical' defaultChecked={configuration['alignmentContentCellVertical'] === 'bottom'} onChange={event => update(updateProperty('alignmentContentCellVertical', 'bottom'))} />Bottom</label>
			</div>
			<div className='flex gap-x-4'>
				<span className='w-20'>ISO Week</span>
				<label><input className='mr-1' id="alignmentWeekCell0" type="radio" name='alignmentWeekCell' defaultChecked={configuration['alignmentWeekCell'] === 'top'} onChange={event => update(updateProperty('alignmentWeekCell', 'top'))} />Top</label>
				<label><input className='mr-1' id="alignmentWeekCell1" type="radio" name='alignmentWeekCell' defaultChecked={configuration['alignmentWeekCell'] === 'middle'} onChange={event => update(updateProperty('alignmentWeekCell', 'middle'))} />Middle</label>
				<label><input className='mr-1' id="alignmentWeekCell2" type="radio" name='alignmentWeekCell' defaultChecked={configuration['alignmentWeekCell'] === 'bottom'} onChange={event => update(updateProperty('alignmentWeekCell', 'bottom'))} />Bottom</label>
			</div>
		</FormGroup>}
		{optionVisible('startOfDay') && <TimeDuration startHour={configuration['startOfDay']} endHour={configuration['endOfDay']} refreshPreview={(start, end) => update(updateProperty('startOfDay', start, 'endOfDay', end)) } />}
		{optionVisible('markedDates') && <FormGroup>
			{labelFor('markedDates', 'Mark Dates')}
			<div className="flex flex-row p-2">
				<div className="p-2"><button onClick={() => setShowSelectDatesModal(true)} type="button" className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-green-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
					Select Dates
				</button></div>
			</div>
		</FormGroup>}

		{showPageTypeSelectorModal && <PageTypeSelector themes={themes} pageLayouts={pageLayouts} configuration={configuration} refreshPreview={updateCalendarSelection} closeModal={() => setPageTypeSelectorModal(false)} />}

		{showThemeSelectorModal && <ThemeSelector themes={themes} configuration={configuration} onThemeSelected={setThemeName} refreshPreview={updateWithCustomTheme} closeModal={() => setShowThemeSelectorModal(false)} />}

		{showCustomizeThemeModal && <CustomizeTheme data={customTheme} refreshPreview={updateWithCustomTheme} closeModal={() => setShowCustomizeThemeModal(false)} />}

		{showSelectDatesModal && <SelectDates data={customMarkingsData} refreshPreview={updateCustomMarkings} importDates={() => { setShowSelectDatesModal(false); setShowImportDatesModal(true);}} exportDates={() => { setShowSelectDatesModal(false); setShowExportDatesModal(true);}} closeModal={() => setShowSelectDatesModal(false)} />}

		{showImportDatesModal && <ImportDates refreshPreview={updateCustomMarkings} closeModal={() => { setShowImportDatesModal(false); setShowSelectDatesModal(true); }} />}
		{showExportDatesModal && <ExportDates data={customMarkingsData} closeModal={() => { setShowExportDatesModal(false); setShowSelectDatesModal(true); }} />}

	</div>
};

export default Customize;