import { Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import MiniCalendar from '../MiniCalendar.js';
import PdfFooter from '../PdfFooter.js';

export const YearPerPageThreeColumnLayout = (props) => {
    const { yearConfig,
        pageTitleTemplateParser,
        orientation,
        styles, xMax, yMax,
        customMarkings, prependZero, startWeekOnSundays, themeName,
        pageSize,
        titleMarginVertical,
        contentMarginLeft,
        contentMarginRight,
        miniCalendarWidth,
        miniCalendarHeight,
        fontSize,
        miniCalendarMarginBottom,
        footerLeftMargin,
        footerRightMargin,
        footerBottomMargin,
        footerFontSize,
        showWeekColumn
    } = props;

    const cellDecorationsForMonth = (month) => {
        const datePrefix = `${yearConfig.year}-${month <= 9 ? '0' : ''}${month}-`;
        return Object.fromEntries(
            customMarkings
                .filter((cm) => cm.date.startsWith(datePrefix))
                .map(cm => [parseInt(cm.date.substring(8), 10), cm.cellDecorations])
        );
    }

    return (<Page key={'year-' + yearConfig.year} size={pageSize} orientation={orientation} style={styles.page}>
        <View style={{ alignItems: 'center', marginVertical: titleMarginVertical, fontSize: 20 }}>
            <Text style={{ flexGrow: 1 }}>{pageTitleTemplateParser(yearConfig.year)}</Text>
        </View>
        <View style={{ flexDirection: 'row', marginLeft: contentMarginLeft, marginRight: contentMarginRight }}>
            {Array(xMax).fill(0).map((_, y) => (
                <View key={'column-' + y} style={styles.section}>
                    {Array(yMax).fill(0).map((_, i) => {
                        if (y + i * xMax + 1 <= yearConfig.months.length) {
                            const month = yearConfig.months[y + i * xMax];

                            return <View key={'calender-' + i}>
                                <MiniCalendar
                                    width={miniCalendarWidth}
                                    height={miniCalendarHeight}
                                    year={yearConfig.year} month={month}
                                    prependZero={prependZero}
                                    startOfWeekDay={startWeekOnSundays ? 0 : 1}
                                    isoWeekColumnMode={showWeekColumn ? 'fixed' : 'none'}
                                    themeName={themeName}
                                    fontSize={fontSize}
                                    cellDecorations={cellDecorationsForMonth(month)} />
                                <View style={{ marginBottom: miniCalendarMarginBottom }} />
                            </View>
                        }

                        return undefined;
                    })}
                </View>))
            }
        </View>
        <PdfFooter
            leftMargin={footerLeftMargin}
            rightMargin={footerRightMargin}
            bottomMargin={footerBottomMargin}
            fontSize={footerFontSize} />
    </Page>)
};