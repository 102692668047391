import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import MiniCalendar from '../MiniCalendar.js';
import PdfFooter from '../PdfFooter.js';

// Create styles
const styles = StyleSheet.create({
	page: {
		marginLeft: '0cm',
		marginRight: '0cm'
	},
});

const monthName = (year, month) => {
	console.log(year, month, new Date(year, month, 1).toLocaleString('default', { month: 'long' }));
	return new Date(year, month - 1, 1).toLocaleString('default', { month: 'long' });
}

const MonthPerPageA4 = (props) => {
	const { years, prependZero, startWeekOnSundays, showWeekColumn, themeName, orientation, customMarkings, pageTitleTemplateParser, weekDays, alignmentContentCellHorizontal, alignmentContentCellVertical, alignmentHeaderCell, alignmentWeekCell } = props;

	const w = orientation === 'portrait' ? 595 - 60 : 842 - 60;
	const h = orientation === 'portrait' ? /*842 - 200*/455 : 595 - 130;

	const bottomMargin = orientation === 'portrait' ? '10.0cm' : '1.0cm';

	const cellDecorationsForMonth = (year, month) => {
        const datePrefix = `${year}-${month <= 9 ? '0' : ''}${month}-`;
        return Object.fromEntries(
            customMarkings
                .filter((cm) => cm.date.startsWith(datePrefix))
                .map(cm => [parseInt(cm.date.substring(8), 10), cm.cellDecorations])
        );
    }

	const cellAlignments = {
		monthCell: {
			horizontal: 'center',
			vertical: 'middle'
		},
		weekNameCell: {
			horizontal: alignmentHeaderCell,
			vertical: 'middle'
		},
		isoWeekCell: {
			horizontal: 'center',
			vertical: alignmentWeekCell
		},
		contentCell: {
			horizontal: alignmentContentCellHorizontal,
			vertical: alignmentContentCellVertical
		},
	};

	return <Document>
		{years.map((yearConfig) =>
			<Page key={'year-' + yearConfig.year} size="A4" orientation={orientation} style={styles.page}>
				{yearConfig.months.map((month) => (
					<View key={'month-' + month}>
						<View style={{ alignItems: 'center', marginVertical: '1cm', fontSize: 40 }}>
							<Text style={{ flexGrow: 1 }}>{pageTitleTemplateParser(yearConfig.year, `${prependZero && month < 10 ? '0' : ''}${month}`, monthName(yearConfig.year, month))}</Text>
						</View>
						<View style={{ flexDirection: 'row', marginLeft: '1cm', marginRight: '1cm', marginBottom: bottomMargin }}>
							<MiniCalendar width={w} height={h} year={yearConfig.year} month={month} prependZero={prependZero} startOfWeekDay={startWeekOnSundays ? 0 : 1} themeName={themeName} fontSize={10} dayNameRowMode='fixed' isoWeekColumnMode={showWeekColumn ? 'fixed' : 'none'} ommitMonthHeader={true} cellDecorations={cellDecorationsForMonth(yearConfig.year, month)} cellAlignments={cellAlignments} weekDays={weekDays} />
						</View>
						<PdfFooter leftMargin={0} rightMargin={'1cm'} bottomMargin={'5mm'} fontSize={10} />
					</View>
				))}
				
			</Page>)}
	</Document>
};

export default MonthPerPageA4;