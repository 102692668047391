
const FormGroup = (props) => {
	const { labelKey, label, children } = props;

	return (<div className="flex flex-col mb-4">
        {labelKey && label && <label htmlFor={labelKey} className="mb-2 uppercase font-bold text-lg text-grey-darkest">{label}</label>}
		{children}
	</div>);
};

export default FormGroup;