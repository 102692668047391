
export const ThemeListContainer = (props) => {
    const { themes, selectedTheme, onThemeSelected } = props;

    return <div className="flex flex-wrap justify-between">
        {Object.keys(themes).map((themeKey) => (
            <div key={themeKey} className="flex flex-col gap-1 mb-4 w-40">
                <div onClick={() => onThemeSelected(themeKey)} className={selectedTheme === themeKey ? 'border-4 border-indigo-500 p-1' : ' p-2'}>
                    <div >
                        <img className='object-contain' src={`calendarThemesThumbnails/${themeKey}.png`} alt={themes[themeKey].displayName ?? themeKey} />
                    </div>
                    <div className="mt-1">{themes[themeKey].displayName ?? themeKey}</div>
                </div>
            </div>
		))}
    </div>
};